import React from 'react';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import 'antd/dist/antd.css';
import { languageToAntdLocaleMapping } from './util/languageToAntdLocaleMapping';

const container = document.getElementById('root')!;
const languageId = parseInt(localStorage.getItem('selectedLanguageId') || '1');

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ConfigProvider locale={languageToAntdLocaleMapping(languageId)}>
          <App />
        </ConfigProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
