import { selectInvoices } from '../../../redux/features/invoices/invoicesSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppSelector } from '../../../redux/hooks';
import { numberFormatter } from '../../../util/utilFunctions';
import './InvoicesTableHeader.scss';

const InvoicesSummaryHeader = () => {
  const { summaryTotalSum, summaryVoidedSum } = useAppSelector(selectInvoices);

  return (
    <span>
      <p>
        {t('filteredAmount')}: <b>{numberFormatter(summaryTotalSum)}</b>
      </p>
      <p style={{ marginBottom: '0px' }}>
        {t('voidedFilteredAmount')}: <b>{numberFormatter(summaryVoidedSum)}</b>
      </p>
    </span>
  );
};

export default InvoicesSummaryHeader;
