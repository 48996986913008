import React, { useEffect } from 'react';
import {
  DownloadOutlined,
  FilePdfOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Carousel, Descriptions, Space, Tooltip } from 'antd';
import Moment from 'moment';
import {
  fetchInvoiceItemsAsync,
  fetchInvoicesAsync,
  selectInvoices,
  setVisibilityInvoiceItemModal,
} from '../../../redux/features/invoices/invoicesSlice';
import { InvoiceTypeEnum } from '../../../redux/features/invoices/InvoiceTypeEnum';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import InvoiceResponse from '../../../redux/response/InvoiceResponse';
import { numberFormatter } from '../../../util/utilFunctions';
import 'antd/dist/antd.css';
import './Carousel.scss';

const InvoicesCarousel = () => {
  const { invoices } = useAppSelector(selectInvoices);
  const dispatch = useAppDispatch(); //TODO:transfer options to a separate component

  useEffect(() => {
    dispatch(fetchInvoicesAsync());
  }, []);

  const handleOnClickInvoiceDownload = (invoiceId: number) => {
    window.open(
      `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadInvoice/${invoiceId}`,
      '_blank'
    );
  };
  const handleOnClickDownloadSpecificationsForInvoice = (invoiceId: number) => {
    window.open(
      `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadSpecification/${invoiceId}`,
      '_blank'
    );
  };

  const handleOnClickViewInvoiceItems = (invoice: InvoiceResponse) => {
    dispatch(fetchInvoiceItemsAsync(invoice));
    dispatch(setVisibilityInvoiceItemModal(true));
  };

  return (
    <Carousel style={{ height: 300 }} autoplay={true} dots={true}>
      {invoices
        .filter((invoice) => {
          return invoice.type === InvoiceTypeEnum.specification;
        })
        .slice(0, 5)
        .map((invoice) => (
          <Descriptions bordered key={invoice.number} size={'small'}>
            <Descriptions.Item
              label={t('invoicesOverview')}
              className={'carouselButtons'}
              span={3}
            >
              <Space style={{ justifyContent: 'right' }}>
                <Tooltip title={t('downloadInvoicesBtn')}>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      handleOnClickInvoiceDownload(invoice.id);
                    }}
                  />
                </Tooltip>
                <Tooltip title={t('invoiceItems')}>
                  <Button
                    icon={<UnorderedListOutlined />}
                    onClick={() => {
                      handleOnClickViewInvoiceItems(invoice);
                    }}
                  />
                </Tooltip>
                <Tooltip title={t('downloadSpecificationsForInvoice')}>
                  <Button
                    icon={<FilePdfOutlined />}
                    onClick={() => {
                      handleOnClickDownloadSpecificationsForInvoice(invoice.id);
                    }}
                  />
                </Tooltip>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item span={3} key={invoice.id} label={t('invoiceId')}>
              {invoice.number}
            </Descriptions.Item>
            <Descriptions.Item span={3} key={invoice.id} label={t('amount')}>
              {numberFormatter(invoice.amount)}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              key={invoice.id}
              label={t('createdDate')}
            >
              {Moment(invoice.createdDate).format('DD.MM.yyyy. HH:mm:ss')}
            </Descriptions.Item>
          </Descriptions>
        ))}
    </Carousel>
  );
};

export default InvoicesCarousel;
