import DashboardPage from '../pages/DashboardPage/DashboardPage';
import InvoicesPage from '../pages/InvoicesPage/InvoicesPage';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import PasswordRecoveryPage from '../pages/PasswordRecoveryPage/PasswordRecoveryPage';
import SpecificationsPage from '../pages/SpecificationsPage/SpecificationsPage';
import TagsPage from '../pages/TagsPage/TagsPage';
import RoutesEnum from './RoutesEnum';

const routes = (isLoggedIn: boolean, isLoginInProgress : boolean, hasLoadedLocalization : boolean) => {
  if (isLoginInProgress || !hasLoadedLocalization)
    return [
      {
        path: '*',
        element: <LoadingPage/>
      }
    ];
  if (isLoggedIn)
    return [
      {
        path: RoutesEnum.HOME,
        element: <DashboardPage />,
      },
      {
        path: RoutesEnum.TAGS,
        element: <TagsPage />,
      },
      {
        path: RoutesEnum.INVOICES,
        element: <InvoicesPage />,
      },
      {
        path: RoutesEnum.SPECIFICATIONS,
        element: <SpecificationsPage />,
      },
      {
        path: RoutesEnum.SPECIFICATIONDEEPLINK,
        element: <SpecificationsPage />,
      },
      {
        path: RoutesEnum.SPECIFICATIONXLSDEEPLINK,
        element: <SpecificationsPage />,
      },
      {
        path: RoutesEnum.INVOICEDEEPLINK,
        element: <InvoicesPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ];

  return [
    {
      path: RoutesEnum.PASSWORDRECOVERY,
      element: <PasswordRecoveryPage />
    },
    {
      path: '*',
      element: <LoginPage />,
    },
  ];
};

export default routes;
