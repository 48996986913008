import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../api/api';
import { RootState } from '../../store';
import { t } from '../translations/translations';

export interface AboutState {
  appVersion: string | null;
  aboutModalVisible: boolean;
  errorMessage: string | null;
}

const initialState: AboutState = {
  appVersion: null,
  aboutModalVisible: false,
  errorMessage: null,
};

export const selectAboutInfo = (state: RootState) => state.specifications;

export const fetchAppVersion = createAsyncThunk(
  'about/fetchVersion',
  async () => {
    const response = await api.get('/portal/hub/version');
    return response.data;
  }
);

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setVisibilityAboutModal: (state, payload: PayloadAction<boolean>) => {
      state.aboutModalVisible = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppVersion.pending, (state) => {
        state.errorMessage = null;
      })
      .addCase(fetchAppVersion.rejected, (state) => {
        state.errorMessage = t('errorLoadingData');
      })
      .addCase(fetchAppVersion.fulfilled, (state, action) => {
        state.appVersion = action.payload.applicationVersion;
      });
  },
});

export const { setVisibilityAboutModal } = aboutSlice.actions;
export default aboutSlice.reducer;
