import React, { useEffect } from 'react';
import { Card } from 'antd';
import {
  fetchVehicleCategorizationAsync,
  selectCommonDependencies,
} from '../../redux/features/dependencies/commonDependenciesSlice';
import { t } from '../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import InvoiceItemModal from '../InvoicesPage/components/InvoiceItemModal';
import { ResponsiveWrapper } from '../wrappers/ResponsiveWrapper';
import ClientInfo from './components/ClientInfo';
import InvoicesCarousel from './components/InvoicesCarousel';
import LineChartTagsHeader from './components/LineChartTagsHeader';
import LineChartTagsSumByDate from './components/LineChartTagsSumByDate';
import PieChartTagsByCategory from './components/PieChartTagsByCategory';
import PieChartTagsHeader from './components/PieChartTagsHeader';
import '../wrappers/responsiveWrapper.scss';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchVehicleCategorizationAsync());
  }, []);

  const { vehicleCategorizationURL } = useAppSelector(selectCommonDependencies);

  return (
    <Card title={t('dashboard')} style={{ marginBottom: 100 }}>
      <>
        <ResponsiveWrapper>
          <div
            className={'limitedMaxWidth'}
            style={{ maxWidth: '50%', flex: 1 }}
          >
            <ClientInfo />
          </div>
          <div
            className={'right-positioned-padded-items limitedMaxWidth'}
            style={{
              flex: 1,
              justifyContent: 'center',
              marginLeft: 20,
              maxWidth: '50%',
            }}
          >
            <InvoicesCarousel />
          </div>
        </ResponsiveWrapper>
        <ResponsiveWrapper>
          <Card
            title={t('tagsSpendingPerPeriod')}
            style={{ flex: 1, maxWidth: '50%' }}
            className={'limitedMaxWidth'}
          >
            <LineChartTagsHeader />
            <LineChartTagsSumByDate />
          </Card>
          <Card
            className={'right-positioned-padded-items limitedMaxWidth'}
            title={
              <span>
                {t('spendingsByCategory')}
                <a
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={vehicleCategorizationURL}
                >
                  {' '}
                  ({t('vehicleCategorisation')})
                </a>
              </span>
            }
            style={{ flex: 1, maxWidth: '50%', marginLeft: 20 }}
          >
            <PieChartTagsHeader />
            <PieChartTagsByCategory />
          </Card>
        </ResponsiveWrapper>
      </>
      <InvoiceItemModal />
    </Card>
  );
};

export default DashboardPage;
