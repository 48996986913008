import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { enpayBig } from '../../assets';
import useErrorMessage from '../../hooks/useErrorMessage';
import useSuccessMessage from '../../hooks/useSuccessMessage';
import {
  changePasswordWithTokenAsync,
  clearMessages,
  loginAsync,
  selectAuth,
  sendTokenAsync,
} from '../../redux/features/auth/authSlice';
import { PasswordRecoveryData } from '../../redux/features/auth/PasswordRecoveryData';
import { selectTheme } from '../../redux/features/theme/themeSlice';
import {
  fetchLocalisationAsync,
  t,
} from '../../redux/features/translations/translations';
import {
  selectLanguages,
  setHasLoadedLocalization,
} from '../../redux/features/translations/translationsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import './PasswordRecoveryPage.scss';

const PasswordRecoveryPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isPasswordChangeInProgress,
    invalidEmailErrorMessage,
    invalidTokenErrorMessage,
    isValidEmail,
    tokenSentMessage,
    loginErrorMessage,
    passwordChangedMessage,
  } = useAppSelector(selectAuth);
  const { isDarkModeActive } = useAppSelector(selectTheme);
  const { languages } = useAppSelector(selectLanguages);
  const [selectedLanguageId, setSelectedLanguageId] = useState(
    localStorage.getItem('selectedLanguageId')
  );

  useEffect(() => {
    return () => {
      dispatch(clearMessages());
    };
  }, []);

  useErrorMessage(
    invalidEmailErrorMessage || invalidTokenErrorMessage || loginErrorMessage
  );
  useSuccessMessage(tokenSentMessage || passwordChangedMessage);

  const languageOptions = () => {
    let lines = [];
    for (let i = 0; i < languages.length; i++) {
      const currentLanguage = languages[i];
      let isSelected = ' ';
      if (selectedLanguageId === currentLanguage.id.toString())
        isSelected += '✔';
      lines[i] = {
        value: currentLanguage.id,
        label: t(currentLanguage.value) + isSelected,
      };
    }
    return lines;
  };

  const onFormSubmit = async (values: PasswordRecoveryData) => {
    if (!isValidEmail) {
      dispatch(sendTokenAsync(values.username));
      return;
    }

    const { payload } = await dispatch(changePasswordWithTokenAsync(values));

    if (!payload) return;
    values.languageId = localStorage.getItem('selectedLanguageId') || '1';
    setTimeout(() => {
      navigate('/');
      dispatch(loginAsync(values));
    }, 1000);
  };

  const handleLanguageChange = (value: string) => {
    localStorage.setItem('selectedLanguageId', value);
    setSelectedLanguageId(value);
    fetchLocalisationAsync(value).then(() => {
      dispatch(setHasLoadedLocalization(true));
    });
    window.location.reload();
  };

  const onGoBackClick = () => {
    navigate('/');
  };

  return (
    <div
      className={`passwordRecoveryPageContainer ${
        isDarkModeActive && 'dark-mode'
      }`}
    >
      <div className="passwordRecoveryPageContent">
        <div style={{ position: 'absolute', top: 10, right: 20 }}>
          <Select
            style={{ borderRadius: '8px' }}
            className={'passwordRecoveryPageLanguageDropdown'}
            placeholder={t('language')}
            onChange={(value) => handleLanguageChange(value)}
            options={languageOptions()}
          />
        </div>
        <div className="logoContainer">
          <img src={enpayBig} alt="ENPay" />
        </div>
        <Form
          name="passwordRecoveryForm"
          className={'passwordRecoveryForm'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <div className={'passwordRecoverySetupMessage'}>
            {t('passwordRecoverySetup')}
          </div>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label={t('emailLabel')}
            name="username"
            rules={[{ required: !isValidEmail, message: t('enterEmail') }]}
          >
            <Input disabled={isValidEmail} style={{ borderRadius: 6 }} />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label={t('token')}
            name="token"
            rules={[{ required: isValidEmail, message: t('token') }]}
          >
            <Input.Password
              disabled={!isValidEmail}
              style={{ borderRadius: 6 }}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label={t('newPassword')}
            name="password"
            rules={[
              { required: isValidEmail, message: t('enterCurrentPassword') },
            ]}
          >
            <Input.Password
              disabled={!isValidEmail}
              style={{ borderRadius: 6 }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Space
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button onClick={onGoBackClick} className="goBackButton">
                {t('goToLogin')}
              </Button>

              <Button
                loading={isPasswordChangeInProgress}
                type="primary"
                htmlType="submit"
                className="confirmPasswordRecoveryButton"
              >
                {t('confirm')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <div className={'linkContainer'}>
          <a
            className={'link'}
            rel={'noreferrer'}
            target={'_blank'}
            href={'https://www.enpay.rs'}
          >
            www.enpay.rs
          </a>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryPage;
