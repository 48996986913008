import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { deleteCountry, fetchCountries, saveCountry } from './countriesAPI';
import Country from './Country';

export interface CountriesState {
  countries: Country[];
  isLoadingCountries: boolean;
  errorMessage: string | null;
  successMessage: string | null;
  isCountryModalVisible: boolean;
  countryToEdit: Country | null;
  isSavingCountryInProgress: boolean;
}

const initialState: CountriesState = {
  countries: [],
  isLoadingCountries: false,
  errorMessage: null,
  successMessage: null,
  isCountryModalVisible: false,
  countryToEdit: null,
  isSavingCountryInProgress: false,
};

export const fetchCountriesAsync = createAsyncThunk(
  'countries/fetchCountries',
  async () => {
    const response = await fetchCountries();
    return response.data;
  }
);

export const deleteCountryAsync = createAsyncThunk(
  'countries/deleteCountry',
  async (id: number) => {
    await deleteCountry(id);
    return id;
  }
);

interface GetStateFunction extends Function {}

export const selectCountries = (state: RootState) => state.countries;

export const saveCountryAsync = createAsyncThunk(
  'countries/saveCountry',
  async (country: Country, { getState }: { getState: GetStateFunction }) => {
    const countries = selectCountries(getState());
    const countryToEdit = countries.countryToEdit;
    const countryToSave = countryToEdit
      ? { ...countryToEdit, ...country }
      : country;
    const response = await saveCountry(countryToSave);
    return response.data;
  }
);

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    showCompanyModal: (state, action: PayloadAction<Country | null>) => {
      state.isCountryModalVisible = true;
      state.countryToEdit = action.payload;
    },
    hideCompanyModal: (state) => {
      state.isCountryModalVisible = false;
    },
    resetCountriesState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesAsync.pending, (state) => {
        state.isLoadingCountries = true;
        state.errorMessage = null;
      })
      .addCase(
        fetchCountriesAsync.fulfilled,
        (state, action: PayloadAction<Country[]>) => {
          state.isLoadingCountries = false;
          state.countries = action.payload;
          state.errorMessage = null;
        }
      )
      .addCase(fetchCountriesAsync.rejected, (state) => {
        state.isLoadingCountries = false;
        state.countries = [];
        state.errorMessage = 'An error has occurred';
      })
      .addCase(deleteCountryAsync.pending, (state) => {
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(deleteCountryAsync.rejected, (state) => {
        state.errorMessage = 'An error has occurred while deleting country';
        state.successMessage = null;
      })
      .addCase(
        deleteCountryAsync.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.errorMessage = null;
          state.successMessage = 'Delete successful';
          state.countries = state.countries.filter(
            (c) => c.id !== action.payload
          );
        }
      )
      .addCase(saveCountryAsync.pending, (state) => {
        state.isSavingCountryInProgress = true;
        state.errorMessage = null;
      })
      .addCase(saveCountryAsync.rejected, (state, action) => {
        state.isSavingCountryInProgress = false;
        state.errorMessage = action.error.message || 'An error has occurred';
      })
      .addCase(
        saveCountryAsync.fulfilled,
        (state, action: PayloadAction<Country>) => {
          state.isSavingCountryInProgress = false;
          state.errorMessage = null;
          state.successMessage = `Country ${action.payload.name} successfully saved`;
          state.isCountryModalVisible = false;

          const countryToUpdate = state.countries.find(
            (c) => c.id === action.payload.id
          );
          if (countryToUpdate) {
            state.countries = state.countries.map((c) =>
              c.id === countryToUpdate.id ? { ...c, ...action.payload } : c
            );
          } else {
            state.countries.push(action.payload);
          }
        }
      );
  },
});

export const { showCompanyModal, hideCompanyModal, resetCountriesState } =
  countriesSlice.actions;

export default countriesSlice.reducer;
