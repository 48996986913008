import axios from 'axios';
import Country from './Country';

export function fetchCountries() {
  return axios.get('/country');
}

export function deleteCountry(id: number) {
  return axios.delete(`/country/${id}`);
}

export function saveCountry(country: Country) {
  if (country.id) {
    return axios.put<Country>(`/country/${country.id}`, country);
  }
  return axios.post<Country>('/country', country);
}
