import { useEffect } from 'react';
import { Image, Modal } from 'antd';
import { enpayInside } from '../../../assets';
import {
  fetchAppVersion,
  setVisibilityAboutModal,
} from '../../../redux/features/about/aboutSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const AboutModal = () => {
  const dispatch = useAppDispatch();
  const { aboutModalVisible, appVersion } = useAppSelector(
    (state) => state.aboutInfo
  );

  const handleCancel = () => {
    dispatch(setVisibilityAboutModal(false));
  };
  useEffect(() => {
    dispatch(fetchAppVersion());
  }, []);

  return (
    <Modal visible={aboutModalVisible} footer={null} onCancel={handleCancel}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: '5px',
        }}
      >
        <Image
          src={enpayInside}
          preview={false}
          onClick={() => {
            window.location.replace('https://www.enpay.rs');
          }}
        />

        <span style={{ paddingTop: '30px' }}>
          {appVersion ? appVersion : ''}
        </span>

        <span>
          ©{new Date().getFullYear()}. AllYes. {t('AllRightsReserved.')}
        </span>

        <a rel={'noreferrer'} target={'_blank'} href={'https://www.enpay.rs'}>
          www.enpay.rs
        </a>
      </div>
    </Modal>
  );
};

export default AboutModal;
