const SVGWrapper = (image: any, widthToHeightRatio?: number) => {
  const style: any = {
    height: 30,
  };
  if (widthToHeightRatio) {
    style.maxWidth = 30 * widthToHeightRatio;
  }

  return (
    <div>
      <img
        className={'vehicleCategory'}
        style={style}
        alt={image}
        src={image}
      />
    </div>
  );
};
export default SVGWrapper;
