import React, { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { enpayBig } from '../../assets';
import useErrorMessage from '../../hooks/useErrorMessage';
import { loginAsync, selectAuth } from '../../redux/features/auth/authSlice';
import LoginData from '../../redux/features/auth/LoginData';
import { selectTheme } from '../../redux/features/theme/themeSlice';
import {
  fetchLocalisationAsync,
  t,
} from '../../redux/features/translations/translations';
import {
  selectLanguages,
  setHasLoadedLocalization,
} from '../../redux/features/translations/translationsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import './LoginPage.scss';
import RoutesEnum from '../../routing/RoutesEnum';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoginInProgress, loginErrorMessage } = useAppSelector(selectAuth);
  const { isDarkModeActive } = useAppSelector(selectTheme);
  const { languages } = useAppSelector(selectLanguages);
  const [selectedLanguageId, setSelectedLanguageId] = useState(
    localStorage.getItem('selectedLanguageId')
  );
  useErrorMessage(loginErrorMessage);

  const languageOptions = () => {
    let lines = [];
    for (let i = 0; i < languages.length; i++) {
      const currentLanguage = languages[i];
      let isSelected = ' ';
      if (selectedLanguageId === currentLanguage.id.toString())
        isSelected += '✔';
      lines[i] = {
        value: currentLanguage.id,
        label: t(currentLanguage.value) + isSelected,
      };
    }
    return lines;
  };

  const onFormSubmit = (values: LoginData) => {
    values.languageId = localStorage.getItem('selectedLanguageId') || '1';
    dispatch(loginAsync(values));
  };

  const handleLanguageChange = (value: string) => {
    localStorage.setItem('selectedLanguageId', value);
    setSelectedLanguageId(value);
    fetchLocalisationAsync(value).then(() => {
      dispatch(setHasLoadedLocalization(true));
    });
    window.location.reload();
  };

  const onForgotPasswordClick = () => {
    navigate(RoutesEnum.PASSWORDRECOVERY);
  };

  return (
    <div className={`loginPageContainer ${isDarkModeActive && 'dark-mode'}`}>
      <div className="loginPageContent">
        <div style={{ position: 'absolute', top: 10, right: 20 }}>
          <Select
            style={{ borderRadius: '8px' }}
            className={'loginPageLanguageDropdown'}
            placeholder={t('language')}
            onChange={(value) => handleLanguageChange(value)}
            options={languageOptions()}
          />
        </div>
        <div className="logoContainer">
          <img src={enpayBig} alt="ENPay" />
        </div>
        <Form
          name="loginForm"
          className={'loginForm'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={false}
            label={t('emailLabel')}
            name="username"
            rules={[{ required: true, message: t('enterEmail') }]}
          >
            <Input style={{ borderRadius: 6 }} />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            className={'currentPasswordContainer'}
            required={false}
            label={t('password')}
            name="password"
            rules={[{ required: true, message: t('enterCurrentPassword') }]}
          >
            <Input.Password style={{ borderRadius: 6 }} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'end' }}>
            <Button
              onClick={onForgotPasswordClick}
              type={'link'}
              className={'forgotPasswordButton'}
            >
              {t('forgotPasswordQuestion')}
            </Button>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'end' }}>
            <Button
              loading={isLoginInProgress}
              type="primary"
              htmlType="submit"
              className="loginButton"
            >
              {t('login')}
            </Button>
          </Form.Item>
        </Form>
        <div
          style={{
            paddingTop: '15px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <a
            style={{ color: 'white' }}
            rel={'noreferrer'}
            target={'_blank'}
            href={'https://www.enpay.rs'}
          >
            www.enpay.rs
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
