import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../api/api';
import { RootState } from '../../store';
import { t } from '../translations/translations';
import { Location } from './Location';
import { Provider } from './Provider';

export interface CommonDependenciesState {
  locations: Location[];
  providers: Provider[];
  vehicleCategorizationURL: string;
  errorMessage: string | null;
  hasLoadedCommonDependencies: boolean;
}

export const selectCommonDependencies = (state: RootState) =>
  state.commonDependencies;

const initialState: CommonDependenciesState = {
  locations: [],
  providers: [],
  vehicleCategorizationURL: '',
  errorMessage: null,
  hasLoadedCommonDependencies: false,
};

export const fetchCommonDependenciesAsync = createAsyncThunk(
  'commonDependencies/fetch',
  async () => {
    const locationsPromise = api.get('/portal/hub/paymentBooth/values');
    const providersPromise = api.get('/portal/hub/distributor/values');

    return Promise.all([locationsPromise, providersPromise]).then(
      ([locationsResponse, providersResponse]) => {
        const locations = locationsResponse.data;
        const providers = providersResponse.data;

        return { locations, providers };
      }
    );
  }
);

export const fetchVehicleCategorizationAsync = createAsyncThunk(
  'commonDependencies/fetchVehicleCategorizationLink',
  async () => {
    const response = await api.get(
      '/portal/hub/settingByKey/webPortal.vehicleCategorisationLink'
    );
    return response.data;
  }
);

export const commonDependenciesSlice = createSlice({
  name: 'commonDependencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonDependenciesAsync.pending, (state) => {
        state.errorMessage = null;
      })
      .addCase(fetchCommonDependenciesAsync.rejected, (state) => {
        state.errorMessage = t('errorDataLoading');
      })
      .addCase(fetchCommonDependenciesAsync.fulfilled, (state, action) => {
        state.locations = action.payload.locations;
        state.providers = action.payload.providers;

        state.hasLoadedCommonDependencies = true;
      })
      .addCase(fetchVehicleCategorizationAsync.fulfilled, (state, action) => {
        state.vehicleCategorizationURL = action.payload.valueSetting;
      });
  },
});

export default commonDependenciesSlice.reducer;
