import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputRef, Select, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import moment from 'moment';
import Moment from 'moment';
import Highlighter from 'react-highlight-words';
import { selectCommonDependencies } from '../../../redux/features/dependencies/commonDependenciesSlice';
import SpecificationResponse from '../../../redux/features/specifications/SpecificationResponse';
import { selectSpecifications } from '../../../redux/features/specifications/specificationsSlice';
import { selectTags } from '../../../redux/features/tags/tagsSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppSelector } from '../../../redux/hooks';
import { TagResponse } from '../../../redux/response/TagResponse';
import { numberFormatter } from '../../../util/utilFunctions';

const { RangePicker } = DatePicker;

const specificationsColumns = function (): ColumnType<SpecificationResponse>[] {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const { tags } = useAppSelector(selectTags);
  const { locations } = useAppSelector(selectCommonDependencies);
  const { specifications } = useAppSelector(selectSpecifications);
  type SpecificationResponseIndex = keyof SpecificationResponse;

  const [map, setMap] = useState<Map<number, TagResponse> | null>(null);

  useEffect(() => {
    const m = new Map<number, TagResponse>();
    for (let specification of specifications) {
      const tag = tags.find((ta) => ta.id === specification.tagId);
      m.set(specification.id, tag!);
    }
    setMap(m);
  }, [tags, specifications]);

  function getGatewayName(id: number) {
    const gateway = locations.find((l) => l.id === id);
    return gateway ? gateway.value : '';
  }

  function renderGatewayIn(_: any, specification: SpecificationResponse) {
    return getGatewayName(specification.gatewayIn);
  }

  function renderGatewayOut(_: any, specification: SpecificationResponse) {
    return getGatewayName(specification.gatewayOut);
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: SpecificationResponseIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (
    dataIndex: SpecificationResponseIndex
  ): ColumnType<SpecificationResponse> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {/*TODO refactor*/}
        {dataIndex === 'dateIn' || dataIndex === 'dateOut' ? (
          <>
            <RangePicker
              onChange={(e) => {
                if (e && e[0] && e[1]) {
                  setSelectedKeys([
                    e[0].toDate().getTime(),
                    e[1].toDate().getTime(),
                  ]);
                } else {
                  setSelectedKeys([]);
                }
              }}
              style={{ marginBottom: 8 }}
            />
            <br />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('filter')}
              </Button>
            </Space>
          </>
        ) : (
          <>
            <Input
              ref={searchInput}
              placeholder={t(`${dataIndex}`)}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('Search')}
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                {t('reset')}
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText((selectedKeys as string[])[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                {t('filter')}
              </Button>
            </Space>
          </>
        )}
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      // TODO refactor -> possibly unnecessary fn at all
      if (dataIndex === 'dateIn' || dataIndex === 'dateOut') return true;

      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return [
    {
      title: t('licencePlate'),
      dataIndex: 'licencePlate',
      sorter: (specification1, specification2) =>
        specification1.licencePlate?.localeCompare(specification2.licencePlate),
      ...getColumnSearchProps('licencePlate'),
    },
    {
      title: t('outerNumber'),
      dataIndex: 'tagId',
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        const arr = tags
          .filter((tag) => tag.outerNumber !== null)
          .map((tag) => ({
            label: tag.outerNumber,
            value: tag.id,
          }));

        return (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 8 }}>
            <Select
              onChange={(value) => {
                if (value) setSelectedKeys([value]);
                else setSelectedKeys([]);
              }}
              allowClear={true}
              filterOption={(input, option) => {
                return option!.label
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ minWidth: 200, marginBottom: 5 }}
              showSearch={true}
              options={arr}
            />
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys as string[], confirm, 'tagId');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t('Search')}
            </Button>
          </div>
        );
      },
      render: (text, specification) => {
        const tag = map?.get(specification.id);
        return tag?.outerNumber;
      },
    },
    {
      title: t('dateIn'),
      dataIndex: 'dateIn',
      sorter: (specification1, specification2) =>
        moment(specification1.dateIn).unix() -
        moment(specification2.dateIn).unix(),
      ...getColumnSearchProps('dateIn'),
      render: (_, specification) => {
        return Moment(specification.dateIn).format('DD.MM.yyyy. HH:mm:ss');
      },
    },
    {
      title: t('placeIn'),
      dataIndex: 'gatewayIn',
      filters: locations.map((loc) => ({
        text: loc.value,
        value: loc.id,
      })),
      onFilter: (value, specification) => specification.gatewayIn === value,
      filterMode: 'tree',
      sorter: (specification1, specification2) =>
        getGatewayName(specification2.gatewayIn).localeCompare(
          getGatewayName(specification1.gatewayIn)
        ),
      render: renderGatewayIn,
    },
    {
      title: t('dateOut'),
      dataIndex: 'dateOut',
      defaultSortOrder: 'descend',
      sorter: (specification1, specification2) =>
        moment(specification1.dateOut).unix() -
        moment(specification2.dateOut).unix(),
      ...getColumnSearchProps('dateOut'),
      render: (_, specification) => {
        return Moment(specification.dateOut).format('DD.MM.yyyy. HH:mm:ss');
      },
    },
    {
      title: t('placeOut'),
      dataIndex: 'gatewayOut',
      filters: locations.map((loc) => ({
        text: loc.value,
        value: loc.id,
      })),
      onFilter: (value, specification) => specification.gatewayOut === value,
      filterMode: 'tree',
      sorter: (specification1, specification2) =>
        getGatewayName(specification2.gatewayOut).localeCompare(
          getGatewayName(specification1.gatewayOut)
        ),
      render: renderGatewayOut,
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      onFilter: (value, invoice) => invoice.amount === value,
      filterMode: 'tree',
      sorter: (invoice1, invoice2) => invoice1.amount - invoice2.amount,
      render: (_, invoice) => (
        <div style={{ textAlign: 'right' }}>
          {numberFormatter(invoice.amount)}
        </div>
      ),
    },
  ];
};

export default specificationsColumns;
