import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../api/api';
import { RootState } from '../../store';
import { Language } from './Language';
import { t } from './translations';

export interface TranslationsState {
  languages: Language[];
  isLoadingLanguages: boolean;
  errorMessage: string | null;
  hasLoadedLocalization: boolean;
}

const initialState: TranslationsState = {
  languages: [],
  isLoadingLanguages: false,
  errorMessage: null,
  hasLoadedLocalization: false,
};

export const selectLanguages = (state: RootState) => state.languages;

export const fetchLanguagesAsync = createAsyncThunk(
  'languages/fetchLanguages',
  async () => {
    const response = await api.get('/portal/languages');
    return response.data;
  }
);

export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setHasLoadedLocalization: (state, action) => {
      state.hasLoadedLocalization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguagesAsync.pending, (state) => {
        state.errorMessage = null;
        state.isLoadingLanguages = true;
      })
      .addCase(fetchLanguagesAsync.rejected, (state) => {
        state.errorMessage = t('ErrorFetchingListOfAvailableLanguages');
        state.isLoadingLanguages = false;
      })
      .addCase(fetchLanguagesAsync.fulfilled, (state, action) => {
        state.isLoadingLanguages = false;
        state.languages = action.payload;
      });
  },
});

export const { setHasLoadedLocalization } = languagesSlice.actions;
export default languagesSlice.reducer;
