import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface ThemeState {
  isDarkModeActive: boolean;
}

const initialState: ThemeState = {
  isDarkModeActive: localStorage.getItem('isDarkModeActive') == 'true',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.isDarkModeActive = !state.isDarkModeActive;
      localStorage.setItem(
        'isDarkModeActive',
        state.isDarkModeActive.toString()
      );
    },
  },
});

export const { changeTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme;

export default themeSlice.reducer;
