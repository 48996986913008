import React, { useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Card, message, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  setDataForExport,
  setExcelModalVisibility,
  setFilename,
} from '../../redux/features/common/commonSlice';
import {
  fetchInvoicesAsync,
  selectInvoices,
} from '../../redux/features/invoices/invoicesSlice';
import { invoicesTypeEnumMapping } from '../../redux/features/invoices/invoicesTypeEnumMapping';
import { t } from '../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import InvoiceResponse from '../../redux/response/InvoiceResponse';
import InvoiceItemModal from './components/InvoiceItemModal';
import InvoicesTable from './components/InvoicesTable';

const InvoicesPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { invoices } = useAppSelector(selectInvoices);
  const [hasOpenedInvoiceDownload, setOpenedInvoiceDownload] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoicesAsync());
    setOpenedInvoiceDownload(false);
  }, []);

  useEffect(() => {
    if (!hasOpenedInvoiceDownload && params.id) {
      const parameterInvoiceId = parseInt(params.id);
      if (
        invoices.find((invoice) => {
          return invoice.id === parameterInvoiceId;
        })
      ) {
        setOpenedInvoiceDownload(true);
        message.success(t('downloadingInvoice'));
        window.open(
          `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadInvoice/${parameterInvoiceId}`,
          '_blank'
        );
      }
    }
  }, [params, invoices]);

  const handleOnClickExportInvoicesCSV = () => {
    dispatch(
      setDataForExport(
        invoices.map((invoice: InvoiceResponse) => ({
          ...invoice,
          type: invoicesTypeEnumMapping(invoice.type),
        }))
      )
    );
    dispatch(setExcelModalVisibility(true));
    dispatch(
      setFilename(
        `${t('specification' + moment(Date.now()).format('hh:mm_DD-MM-YYYY'))}`
      )
    );
  };

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography.Text>{t('invoices')}</Typography.Text>
          <div>
            <Tooltip title={t('exportCsvFile')}>
              <Button
                icon={<FileExcelOutlined />}
                onClick={() => {
                  handleOnClickExportInvoicesCSV();
                }}
                title={t('exportCsvFile')}
              >
                {t('exportCsvFile')}
              </Button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <InvoicesTable />
      <InvoiceItemModal />
    </Card>
  );
};

export default InvoicesPage;
