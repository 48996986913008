export const invoicesTypeEnumMapping = (typeId: number) => {
  switch (typeId) {
    case 1: {
      return 'specification';
    }
    case 2: {
      return 'tags';
    }
    case 3: {
      return 'RESERVATIONS';
    }
    case 4: {
      return 'FEES';
    }
    case 5: {
      return 'MANUAL_PAYMENT';
    }
    case 10: {
      return 'AMSS_MEMBERSHIP';
    }
    default: {
      return 'OTHER';
    }
  }
};
