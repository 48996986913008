import { useEffect } from 'react';
import { Line } from '@ant-design/charts';
import {
  fetchTagsSumByDate,
  selectTags,
} from '../../../redux/features/tags/tagsSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const LineChartTagsSumByDate = () => {
  const { selectedTagsSumOption } = useAppSelector(selectTags);
  const dispatch = useAppDispatch();
  const { tagsSumByDate } = useAppSelector(selectTags);

  useEffect(() => {
    dispatch(fetchTagsSumByDate(selectedTagsSumOption));
  }, [selectedTagsSumOption]);

  const config = {
    data: tagsSumByDate,
    xField: 'date',
    yField: 'amount',
    yAxis: {
      title: {
        visible: true,
        text: t('amount'),
      },
    },
    xAxis: {
      tickCount: 7,
      title: {
        visible: true,
        text: t('date'),
      },
    },
  };

  return <Line {...config} />;
};
export default LineChartTagsSumByDate;
