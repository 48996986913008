import { useEffect } from 'react';
import { Descriptions } from 'antd';
import {
  fetchClientInfoAsync,
  selectAuth,
} from '../../../redux/features/auth/authSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const ClientInfo = () => {
  const dispatch = useAppDispatch();
  const { clientInfo, authenticatedUser } = useAppSelector(selectAuth);

  useEffect(() => {
    dispatch(fetchClientInfoAsync());
  }, []);

  return (
    <Descriptions bordered size={'small'} layout={'vertical'}>
      <Descriptions.Item span={3} label={t('companyName')}>
        {authenticatedUser?.name}
      </Descriptions.Item>
      <Descriptions.Item label={t('name')}>
        {clientInfo?.firstName} {clientInfo?.lastName} ({clientInfo?.email})
      </Descriptions.Item>
      <Descriptions.Item label={t('location')}>
        {clientInfo?.address} {clientInfo?.city} {clientInfo?.postalCode}
      </Descriptions.Item>
      <Descriptions.Item label={t('phone')}>
        {clientInfo?.phone}
      </Descriptions.Item>
      <Descriptions.Item label={t('pib')}>
        {authenticatedUser?.pib}
      </Descriptions.Item>
      <Descriptions.Item label={t('mb')}>
        {authenticatedUser?.mb}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ClientInfo;
