import { DownOutlined } from '@ant-design/icons';
import { DatePicker, Dropdown, Menu, Space } from 'antd';
import {
  setDatesForFetchingTagsSumByDate,
  setSelectedPeriodForFetchingTagsSumByDate,
} from '../../../redux/features/tags/tagsSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch } from '../../../redux/hooks';
import { TagsByDatePeriodOption } from '../../../redux/request/TagsByDatePeriodOption';

const { RangePicker } = DatePicker;

const LineChartTagsHeader = () => {
  const dispatch = useAppDispatch();

  const handlePeriodChange = (period: any) => {
    dispatch(setSelectedPeriodForFetchingTagsSumByDate(period));
  };

  const menu = (
    <Menu
      onSelect={(period) => handlePeriodChange(period.key)}
      selectable
      items={[
        {
          key: TagsByDatePeriodOption.day,
          label: t('by_days'),
        },
        {
          key: TagsByDatePeriodOption.month,
          label: t('by_months'),
        },
      ]}
    />
  );

  const handleRangePickerChange = ([from, to]: [string, string]) => {
    dispatch(
      setDatesForFetchingTagsSumByDate({
        from: new Date(from).getTime(),
        to: new Date(to).getTime() + 24 * 60 * 60 * 1000, //include end date by adding one day to ms
      })
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Space direction={'horizontal'}>
        <Dropdown overlay={menu}>
          <Space>
            {t('period')}
            <DownOutlined />
          </Space>
        </Dropdown>

        <RangePicker onChange={(_, dates) => handleRangePickerChange(dates)} />
      </Space>
    </div>
  );
};

export default LineChartTagsHeader;
