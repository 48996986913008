import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import './App.scss';
import { enpayInside } from './assets';
import Header from './components/layout/Header/Header';
import siteMenuItems from './components/layout/siteMenuItems';
import useAntdDarkMode from './hooks/useAntdDarkMode';
import AboutModal from './pages/modals/AboutModal/AboutModal';
import ChangePasswordModal from './pages/modals/ChangePasswordModal/ChangePasswordModal';
import { checkStateAsync, selectAuth } from './redux/features/auth/authSlice';
import { fetchCommonDependenciesAsync } from './redux/features/dependencies/commonDependenciesSlice';
import { changeTheme, selectTheme } from './redux/features/theme/themeSlice';
import { fetchLocalisationAsync } from './redux/features/translations/translations';
import {
  fetchLanguagesAsync,
  selectLanguages,
  setHasLoadedLocalization,
} from './redux/features/translations/translationsSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import routes from './routing/routes';
import SelectableExportToCSV from './util/SelectableExportToCSV';

const { Footer, Content, Sider } = Layout;

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { languages, hasLoadedLocalization } = useAppSelector(selectLanguages);
  const { isDarkModeActive } = useAppSelector(selectTheme);
  let { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState(pathname);

  useLayoutEffect(() => {
    setCurrentPage(pathname);
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchLanguagesAsync());
    dispatch(checkStateAsync());
    const selectedLanguageId = localStorage.getItem('selectedLanguageId');
    const darkModeSetting = localStorage.getItem('isDarkModeActive');
    if (darkModeSetting) {
      if (darkModeSetting == 'true' && !isDarkModeActive) {
        dispatch(changeTheme());
      } else if (darkModeSetting == 'false' && isDarkModeActive) {
        dispatch(changeTheme());
      }
    }
    if (selectedLanguageId) {
      fetchLocalisationAsync(selectedLanguageId).then(() => {
        dispatch(setHasLoadedLocalization(true));
      });
    } else {
      if (languages[0]) {
        fetchLocalisationAsync(languages[0].id.toString()).then(() => {
          dispatch(setHasLoadedLocalization(true));
        });
      } else {
        fetchLocalisationAsync('1').then(() => {
          dispatch(setHasLoadedLocalization(true));
        }); //TODO:refactor
      }
    }
  }, []);

  const { isAuthenticated, isLoginInProgress } = useAppSelector(selectAuth);
  const routing = useRoutes(
    routes(isAuthenticated, isLoginInProgress, hasLoadedLocalization)
  );

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchCommonDependenciesAsync());
  }, [isAuthenticated]);

  useAntdDarkMode(isDarkModeActive);
  const theme = isDarkModeActive ? 'dark' : 'light';

  const renderRoutesWithoutLayout =
    isLoginInProgress || !hasLoadedLocalization || !isAuthenticated;

  if (renderRoutesWithoutLayout) return <>{routing}</>;

  return (
    <Layout hasSider>
      <Sider
        theme={theme}
        collapsible={true}
        className="sider"
        style={{ position: 'sticky' }}
      >
        <div className="logo">
          <NavLink target={'_self'} to={'/'}>
            <img
              src={enpayInside}
              alt=""
              style={{ width: '200px', paddingTop: '10px' }}
            />
          </NavLink>
        </div>
        <Menu
          onSelect={(e) => {
            // @ts-ignore
            navigate(e.item.props.link);
          }}
          theme={theme}
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[currentPage]}
          items={siteMenuItems()}
          style={{ overflowX: 'hidden', overflowY: 'hidden' }}
        />
      </Sider>
      <Layout className={`site-layout ${isDarkModeActive && 'dark-mode'}`}>
        <Header />
        <AboutModal />
        <ChangePasswordModal />
        <SelectableExportToCSV />
        <Content className="content">{routing}</Content>
        <Footer className="footer">
          ©{new Date().getFullYear()} Created by Planet soft
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
