import { useEffect, useState } from 'react';

function useWindowResize() {
  const [screenSize, setScreenSize] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      setScreenSize({
        screenWidth,
        screenHeight,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { screenWidth, screenHeight } = screenSize;

  return {
    screenWidth,
    screenHeight,
  };
}

export { useWindowResize };
