import { useEffect } from 'react';
import { Card } from 'antd';
import { fetchTagsAsync } from '../../redux/features/tags/tagsSlice';
import { t } from '../../redux/features/translations/translations';
import { useAppDispatch } from '../../redux/hooks';
import TagsTable from './components/TagsTable';

const TagsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTagsAsync());
  }, []);

  return (
    <Card title={t('tags')}>
      <TagsTable />
    </Card>
  );
};

export default TagsPage;
