import en_US from 'antd/es/locale/en_US';
import sr_RS from 'antd/es/locale/sr_RS';

export const languageToAntdLocaleMapping = (languageId: number) => {
  if (languageId === 1) {
    return sr_RS;
  }
  if (languageId === 3) {
    return en_US;
  }
  return en_US;
};
