import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface CommonState {
  isExportSelectableExcelModalVisible: boolean;
  dataForExport: any;
  fileName: string;
}

const initialState: CommonState = {
  isExportSelectableExcelModalVisible: false,
  dataForExport: null,
  fileName: '',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setExcelModalVisibility: (state, action) => {
      state.isExportSelectableExcelModalVisible = action.payload;
    },
    setDataForExport: (state, action) => {
      state.dataForExport = action.payload;
    },
    setFilename: (state, action) => {
      state.fileName = action.payload;
    },
  },
});

export const selectCommonStates = (state: RootState) => state.common;

export const { setExcelModalVisibility, setDataForExport, setFilename } =
  commonSlice.actions;

export default commonSlice.reducer;
