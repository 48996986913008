import React, { useEffect } from 'react';
import { Table } from 'antd';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  invokePagingAsync,
  selectSpecifications,
  setServersideConfig,
} from '../../../redux/features/specifications/specificationsSlice';
import { fetchTagsAsync } from '../../../redux/features/tags/tagsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import specificationsColumns from './specificationsColumns';

const SpecificationsTable = () => {
  const dispatch = useAppDispatch();

  const {
    specifications,
    filteredSpecificationsCount,
    isLoadingSpecifications,
    errorMessage,
  } = useAppSelector(selectSpecifications);

  useErrorMessage(errorMessage);

  useEffect(() => {
    const loadDependencies = async () => {
      await dispatch(invokePagingAsync());
      await dispatch(fetchTagsAsync());
    };

    loadDependencies();
  }, []);

  return (
    <Table
      size={'small'}
      loading={isLoadingSpecifications}
      columns={specificationsColumns()}
      dataSource={specifications}
      rowKey="id"
      pagination={{
        defaultPageSize: 20,
        position: ['topRight'],
        total: filteredSpecificationsCount,
      }}
      scroll={{ y: 'calc(100vh - 300px)' }}
      onChange={(pagination, filters, sorter) => {
        const serversideConfig = {
          pagination,
          filters,
          sorter,
        };
        dispatch(setServersideConfig(serversideConfig));
        dispatch(invokePagingAsync(serversideConfig));
      }}
    />
  );
};

export default SpecificationsTable;
