export const numberFormatter = (number: number) => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function getKeysFromJSONObj(data: any, keys: string[] = []) {
  if (data) {
    Object.keys(data[0]).forEach((key) => {
      keys.push(key);
    });
  }

  return keys;
}
