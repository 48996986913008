import { useEffect, useState } from 'react';
import { G2, Pie } from '@ant-design/charts';
import {
  fetchTagsByCategoryAsync,
  selectTags,
} from '../../../redux/features/tags/tagsSlice';
import { tagVehicleCategoryEnumMapping } from '../../../redux/features/tags/tagVehicleCategoryEnumMapping';
import { selectTheme } from '../../../redux/features/theme/themeSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { numberFormatter } from '../../../util/utilFunctions';

const PieChartTagsByCategory = () => {
  const dispatch = useAppDispatch();
  const {
    isLoadingTagsByCategory,
    tagsByCategory,
    selectedTagsSumByCategoryOption,
  } = useAppSelector(selectTags);
  const { isDarkModeActive } = useAppSelector(selectTheme);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    let sum = 0;
    for (const tag of tagsByCategory) {
      sum += tag.value;
    }
    setTotalSum(sum);
  }, [tagsByCategory]);

  useEffect(() => {
    dispatch(fetchTagsByCategoryAsync(selectedTagsSumByCategoryOption));
  }, [selectedTagsSumByCategoryOption]);

  const G = G2.getEngine('canvas');

  const config = {
    loading: isLoadingTagsByCategory,
    appendPadding: 10,
    data: tagsByCategory,

    legend: false,
    angleField: 'value',
    colorField: 'key',
    seriesField: 'value',
    radius: 0.75,
    tooltip: {
      formatter: (category: any) => {
        return {
          name: `${tagVehicleCategoryEnumMapping(category.key)} ${t(
            'category'
          )},  ${((category.value * 100) / totalSum).toFixed(2) + '%'}`,
          value: numberFormatter(category.value),
        };
      },
    },
    color: ['#f4a261', '#e9c46a', '#e76f51', '#264653', '#2a9d8f'],
    label: {
      type: 'spider',
      theme: isDarkModeActive ? 'dark' : 'default',
      labelHeight: 40,
      formatter: (
        dat: { type: any; value: any; percent: number; key: number },
        mappingData: { color: any }
      ) => {
        const group = new G.Group({});
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 10,
            y: 8,
            text: `${tagVehicleCategoryEnumMapping(dat.key)} ${t('category')}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 0,
            y: 25,
            text: `${numberFormatter(dat.value)}`,
            fill: isDarkModeActive
              ? 'rgba(255,255,255,0.65)'
              : 'rgba(0, 0, 0, 0.65)',
            fontWeight: 700,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 0,
            y: 40,
            text: `${(dat.percent * 100).toFixed(2)}%`,
            fill: isDarkModeActive
              ? 'rgba(255,255,255,0.65)'
              : 'rgba(0, 0, 0, 0.65)',
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  // @ts-ignore
  return <Pie {...config} />;
};
export default PieChartTagsByCategory;
