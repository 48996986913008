import { useEffect, useState } from 'react';
import { Card, message, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { t } from '../../redux/features/translations/translations';
import FilterSpecificationsButton from './components/FilterSpecificationButton';
import SpecificationsTable from './components/SpecificationsTable';

const SpecificationsPage = () => {
  const params = useParams();
  const [hasOpenedInvoiceDownload, setOpenedInvoiceDownload] = useState(false);

  useEffect(() => {
    setOpenedInvoiceDownload(false);
  }, []);

  useEffect(() => {
    if (!hasOpenedInvoiceDownload && params.id) {
      const parameterSpecificationId = parseInt(params.id);

      if (params.format === 'xls') {
        setOpenedInvoiceDownload(true);
        message.success(t('downloadingInvoice'));
        window.open(
          `${process.env.REACT_APP_URL}/portal/hub/xls/downloadSpecification/${parameterSpecificationId}`,
          '_blank'
        );
      } else {
        setOpenedInvoiceDownload(true);
        message.success(t('downloadingInvoice'));
        window.open(
          `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadSpecification/${parameterSpecificationId}`,
          '_blank'
        );
      }
    }
  }, [params]);

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography.Text>{t('specification')}</Typography.Text>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <Space>
              <FilterSpecificationsButton fileType="CSV" />
              <FilterSpecificationsButton fileType="XLS" />
            </Space>
          </div>
        </div>
      }
    >
      <SpecificationsTable />
    </Card>
  );
};

export default SpecificationsPage;
