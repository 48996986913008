import { useEffect, useState } from 'react';
import { useWindowResize } from '../../util/useWindowResize';
import './responsiveWrapper.scss';

export const ResponsiveWrapper = (props: { children: any }) => {
  const { children } = props;
  const SMALL_WIDTH_THRESHOLD: number = 1000;
  const { screenWidth } = useWindowResize();
  const [isDirectionVertical, setIsDirectionVertical] = useState(false);

  useEffect(() => {
    if (screenWidth <= SMALL_WIDTH_THRESHOLD) {
      setIsDirectionVertical(true);
    } else {
      setIsDirectionVertical(false);
    }
  }, [screenWidth]);

  return (
    <div className={isDirectionVertical ? 'wrapper-column' : 'wrapper-row'}>
      {children}
    </div>
  );
};
