import { useEffect, useState } from 'react';

const useAntdDarkMode = (isDarkModeActive: boolean) => {
  const [stylePath, setStylePath] = useState('');

  useEffect(() => {
    if (!stylePath) return;

    const head = document.head;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = stylePath;

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, [stylePath]);

  useEffect(() => {
    setStylePath(isDarkModeActive ? '../darkAnt.css' : '');
  }, [isDarkModeActive]);
};

export default useAntdDarkMode;
