import { useEffect } from 'react';
import { message } from 'antd';

const useSuccessMessage = (successMessage: string | null) => {
  useEffect(() => {
    if (successMessage) message.success(successMessage);
  }, [successMessage]);
};

export default useSuccessMessage;
