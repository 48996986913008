import React from 'react';
import { Result } from 'antd';
import { t } from '../../redux/features/translations/translations';

const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result status="404" title="404" subTitle={t('404message')} />
    </div>
  );
};

export default NotFound;
