import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../api/api';
import ClientInfoResponse from '../../response/ClientInfoResponse';
import LoginResponse from '../../response/LoginResponse';
import { RootState } from '../../store';
import { t } from '../translations/translations';
import ChangePasswordData from './ChangePasswordData';
import LoginData from './LoginData';

export interface AuthState {
  isLoginInProgress: boolean;
  isPasswordChangeInProgress: boolean;
  isAuthenticated: boolean;
  isValidEmail: boolean;
  isValidToken: boolean;
  loginErrorMessage: string | null;
  logoutErrorMessage: string | null;
  invalidEmailErrorMessage: string | null;
  invalidTokenErrorMessage: string | null;
  passwordChangedMessage: string | null;
  tokenSentMessage: string | null;
  authenticatedUser: LoginResponse | null;
  oldPassword: string | undefined;
  newPassword: string | undefined;
  repeatNewPassword: string | undefined;
  waitingResponseForChangedPassword: boolean;
  changingPasswordError: string | null;
  changePasswordModalVisible: boolean;
  changingPasswordSuccessMessage: string | null;
  clientInfo: ClientInfoResponse | null;
  isLoadingClientInfo: boolean;
  loadingClientInfoMessage: string | null;
}

const initialState: AuthState = {
  isLoginInProgress: false,
  isPasswordChangeInProgress: false,
  isAuthenticated: false,
  isValidEmail: false,
  isValidToken: false,
  loginErrorMessage: null,
  logoutErrorMessage: null,
  invalidEmailErrorMessage: null,
  invalidTokenErrorMessage: null,
  passwordChangedMessage: null,
  tokenSentMessage: null,
  authenticatedUser: null,
  oldPassword: undefined,
  newPassword: undefined,
  repeatNewPassword: undefined,
  changePasswordModalVisible: false,
  waitingResponseForChangedPassword: false,
  changingPasswordError: null,
  changingPasswordSuccessMessage: null,
  clientInfo: null,
  isLoadingClientInfo: false,
  loadingClientInfoMessage: null,
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (loginData: LoginData) => {
    const response = await api.post('/portal/login', loginData);
    return response.data;
  }
);

export const sendTokenAsync = createAsyncThunk(
  'auth/sendToken',
  async (email: string) => {
    await api.post('/portal/generateToken', { email });
  }
);

export const changePasswordWithTokenAsync = createAsyncThunk(
  'auth/changePasswordWithTokenAsync',
  async (data: { username: string; token: string; password: string }) => {
    await api.post('/portal/resetPassword', {
      email: data.username,
      token: data.token,
      password: data.password,
    });
    return true;
  }
);

export const changePasswordAsync = createAsyncThunk(
  'auth/changePassword',
  async (data: ChangePasswordData) => {
    const response = await api.put('/portal/hub/changePassword', data);
    return response.data;
  }
);

export const logoutAsync = createAsyncThunk('auth/logout', async () => {
  const response = await api.get('/portal/hub/logout');
  return response.data;
});

export const fetchClientInfoAsync = createAsyncThunk(
  'auth/fetchClientInfo',
  async () => {
    const response = await api.get('/portal/hub/clientInfo');
    return response.data;
  }
);

export const checkStateAsync = createAsyncThunk('auth/checkState', async () => {
  const response = await api.get('/portal/hub/state');
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.passwordChangedMessage = null;
      state.invalidTokenErrorMessage = null;
      state.tokenSentMessage = null;
      state.loginErrorMessage = null;
      state.invalidEmailErrorMessage = null;
      state.isValidEmail = false;
      state.isValidToken = false;
    },
    setOldPassword: (state, action) => {
      state.oldPassword = action.payload;
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },
    setRepeatedNewPassword: (state, action) => {
      state.repeatNewPassword = action.payload;
    },
    setChangingPasswordError: (state, action) => {
      state.changingPasswordError = action.payload;
    },
    setUserLoggedOut: (state) => {
      state.isAuthenticated = false;
    },
    setChangePasswordModalVisible: (state, action: PayloadAction<boolean>) => {
      state.changePasswordModalVisible = action.payload;
      state.oldPassword = undefined;
      state.newPassword = undefined;
      state.repeatNewPassword = undefined;
    },
    setChangingPasswordSuccess: (state, action) => {
      state.changingPasswordSuccessMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoginInProgress = true;
        state.loginErrorMessage = null;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.isLoginInProgress = false;
        state.loginErrorMessage = t('badLogin');
      })
      .addCase(
        loginAsync.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.isLoginInProgress = false;
          state.isAuthenticated = true;
          state.authenticatedUser = action.payload;
        }
      )
      .addCase(sendTokenAsync.pending, (state) => {
        state.isValidEmail = false;
        state.tokenSentMessage = null;
        state.invalidEmailErrorMessage = null;
        state.isPasswordChangeInProgress = true;
      })
      .addCase(sendTokenAsync.fulfilled, (state) => {
        state.isValidEmail = true;
        state.tokenSentMessage = t('tokenSent');
        state.isPasswordChangeInProgress = false;
      })
      .addCase(sendTokenAsync.rejected, (state) => {
        state.isValidEmail = false;
        state.invalidEmailErrorMessage = t('invalidEmail');
        state.isPasswordChangeInProgress = false;
      })
      .addCase(changePasswordWithTokenAsync.pending, (state) => {
        state.isPasswordChangeInProgress = true;
        state.isValidToken = false;
        state.invalidTokenErrorMessage = null;
        state.tokenSentMessage = null;
        state.passwordChangedMessage = null;
      })
      .addCase(changePasswordWithTokenAsync.fulfilled, (state) => {
        state.passwordChangedMessage = t('passwordChangedSuccessfully');
        state.isValidToken = true;
        state.isPasswordChangeInProgress = false;
      })
      .addCase(changePasswordWithTokenAsync.rejected, (state) => {
        state.isValidToken = false;
        state.isPasswordChangeInProgress = false;
        state.invalidTokenErrorMessage = t('incorrectToken');
      })
      .addCase(logoutAsync.rejected, (state) => {
        state.isLoginInProgress = false;
        state.logoutErrorMessage = t('logoutError');
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.authenticatedUser = null;
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.waitingResponseForChangedPassword = true;
        state.changingPasswordError = null;
        state.changingPasswordSuccessMessage = null;
      })
      .addCase(changePasswordAsync.rejected, (state) => {
        state.waitingResponseForChangedPassword = false;
        state.changingPasswordError = t('errorPasswordChange');
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.waitingResponseForChangedPassword = false;
        state.changingPasswordSuccessMessage = t('passwordChangedSuccess');
      })
      .addCase(fetchClientInfoAsync.pending, (state) => {
        state.isLoadingClientInfo = true;
        state.loadingClientInfoMessage = null;
      })
      .addCase(fetchClientInfoAsync.rejected, (state) => {
        state.isLoadingClientInfo = false;
        state.loadingClientInfoMessage = t('errorLoadingData');
      })
      .addCase(
        fetchClientInfoAsync.fulfilled,
        (state, action: PayloadAction<ClientInfoResponse>) => {
          state.isLoadingClientInfo = false;
          state.clientInfo = action.payload;
        }
      )
      .addCase(checkStateAsync.pending, (state) => {
        state.isLoginInProgress = true;
        state.loginErrorMessage = null;
      })
      .addCase(checkStateAsync.rejected, (state) => {
        state.isLoginInProgress = false;
      })
      .addCase(checkStateAsync.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.isLoginInProgress = false;
        state.authenticatedUser = action.payload;
      });
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
  setNewPassword,
  setOldPassword,
  setRepeatedNewPassword,
  setChangingPasswordError,
  setChangePasswordModalVisible,
  setUserLoggedOut,
  clearMessages,
} = authSlice.actions;
export default authSlice.reducer;
