import { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { CSVLink } from 'react-csv';
import {
  selectCommonStates,
  setExcelModalVisibility,
} from '../redux/features/common/commonSlice';
import { t } from '../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getKeysFromJSONObj } from './utilFunctions';

const { Option } = Select;
export const SelectableExportToCSV = () => {
  const dispatch = useAppDispatch();
  const { isExportSelectableExcelModalVisible, dataForExport, fileName } =
    useAppSelector(selectCommonStates);
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; key: string }[]
  >([]);

  const handleChange = (option: any) => {
    let selectedOpts: { label: string; key: string }[] = [];
    for (const opt of option) {
      const currOption = { label: t(opt.value), key: opt.value };
      if (selectedOpts.indexOf(currOption) === -1)
        selectedOpts.push(currOption);
    }

    setSelectedOptions(selectedOpts);
  };

  const handleCancel = () => {
    dispatch(setExcelModalVisibility(false));
  };

  return (
    <Modal
      title={t('exportCsvFile')}
      footer={null}
      visible={isExportSelectableExcelModalVisible}
      onCancel={handleCancel}
    >
      <Select
        style={{ width: '100%', paddingTop: 20 }}
        mode="multiple"
        allowClear
        placeholder={t('selectColumnsToExport')}
        onChange={(_: any, option: any) => handleChange(option)}
      >
        {getKeysFromJSONObj(dataForExport).map((key) => (
          <Option key={key}>{t(key)}</Option>
        ))}
      </Select>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          style={{ marginTop: 10 }}
          disabled={selectedOptions.length === 0}
        >
          <CSVLink
            data={dataForExport}
            headers={selectedOptions}
            filename={fileName}
          >
            {t('download')}
          </CSVLink>
        </Button>
      </div>
    </Modal>
  );
};

export default SelectableExportToCSV;
