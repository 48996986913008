import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import {
  defaultServersideConfig,
  getSearchFilter,
  getSorterOrder,
  selectSpecifications,
} from '../../../redux/features/specifications/specificationsSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppSelector } from '../../../redux/hooks';
import FileType from '../../../redux/model/FileType';

const FilteredSpecificationsButton = ({ fileType }: { fileType: FileType }) => {
  const { serversideConfig, filteredSpecificationsCount } =
    useAppSelector(selectSpecifications);
  return (
    <Tooltip
      title={fileType === 'CSV' ? t('exportCSVFile') : t('exportXlsFile')}
    >
      <Button
        icon={<FileExcelOutlined />}
        onClick={() => {
          let pagination, filters, sorter, sortOrder, searchFilter;
          if (!serversideConfig) {
            pagination = defaultServersideConfig.pagination;
            pagination.total = filteredSpecificationsCount;
            filters = defaultServersideConfig.filters;
            sortOrder = defaultServersideConfig.sortOrder;

            searchFilter = getSearchFilter(filters);
          } else {
            pagination = serversideConfig.pagination;
            filters = serversideConfig.filters;
            sorter = serversideConfig.sorter;
            sortOrder = getSorterOrder(sorter);
            searchFilter = getSearchFilter(filters);
          }
          window.open('', 'GenerateVoucherPackageWindow' + fileType);
          // @ts-ignore
          document.getElementById('pageSize' + fileType).value =
            pagination.total;
          // @ts-ignore
          document.getElementById('searchFilter' + fileType).value =
            JSON.stringify(searchFilter);
          // @ts-ignore
          document.getElementById('sortOrder' + fileType).value =
            JSON.stringify(sortOrder);

          const formRef = document.getElementById(
            'GenerateVoucherPackageForm' + fileType
          );
          if (formRef) {
            // @ts-ignore
            formRef.submit();
          }
        }}
      >
        {fileType === 'CSV' ? t('exportCsvFile') : t('exportXlsFile')}
      </Button>

      <form
        id={'GenerateVoucherPackageForm' + fileType}
        method="post"
        action={`${process.env.REACT_APP_URL}/portal/hub/specification/downloadAllWithFilter/${fileType}`}
        target={'GenerateVoucherPackageWindow' + fileType}
      >
        <input
          type="hidden"
          name="pageNumber"
          id={'pageNumber' + fileType}
          value={0}
        />
        <input type="hidden" name="pageSize" id={'pageSize' + fileType} />
        <input
          type="hidden"
          name="searchFilter"
          id={'searchFilter' + fileType}
        />
        <input
          type="hidden"
          name="sortOrder"
          id={'sortOrder' + fileType}
          value={'{ascendingOrder: [], descendingOrder: []}'}
        />
      </form>
    </Tooltip>
  );
};

export default FilteredSpecificationsButton;
