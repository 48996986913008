enum RoutesEnum {
  LOGIN = '/login',
  HOME = '/',
  TAGS = '/tags',
  INVOICES = '/invoices',
  SPECIFICATIONS = '/specifications',
  SPECIFICATIONDEEPLINK = '/specifications/:id',
  SPECIFICATIONXLSDEEPLINK = '/specifications/:format/:id',
  INVOICEDEEPLINK = '/invoices/:id',
  PASSWORDRECOVERY = '/passwordrecovery',
}

export default RoutesEnum;
