import { useState } from 'react';
import {
  EditOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { setVisibilityAboutModal } from '../../../redux/features/about/aboutSlice';
import {
  logoutAsync,
  setChangePasswordModalVisible,
} from '../../../redux/features/auth/authSlice';
import {
  fetchLocalisationAsync,
  t,
} from '../../../redux/features/translations/translations';
import {
  selectLanguages,
  setHasLoadedLocalization,
} from '../../../redux/features/translations/translationsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

export const HeaderOptionsDropdownMenu = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguageId')
  );

  const { languages } = useAppSelector(selectLanguages);
  const dispatch = useAppDispatch();

  let childrenLanguageMenu = [];
  for (let lang of languages) {
    childrenLanguageMenu.push({ label: t(lang.value), key: lang.id });
  }
  const handleLanguageChange = (languageId: string) => {
    localStorage.setItem('selectedLanguageId', languageId);
    fetchLocalisationAsync(languageId).then(() => {
      dispatch(setHasLoadedLocalization(true));
      setSelectedLanguage(languageId);
      window.location.reload();
    });
  };

  const handleViewAppInfo = () => {
    dispatch(setVisibilityAboutModal(true));
  };
  const handleChangePasswordClick = () => {
    dispatch(setChangePasswordModalVisible(true));
  };

  const menu = (
    <Menu>
      <Menu.SubMenu
        title={
          <span>
            <MenuFoldOutlined /> {t('language')}
          </span>
        }
      >
        {childrenLanguageMenu.map((child) => (
          <Menu.Item
            onClick={() => {
              handleLanguageChange(child.key.toString());
            }}
            key={child.key}
          >
            {t(child.label)} {child.key.toString() === selectedLanguage && '✔'}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item
        icon={<InfoCircleOutlined />}
        onClick={() => {
          handleViewAppInfo();
        }}
      >
        {t('about')}
      </Menu.Item>
      <Menu.Item
        icon={<EditOutlined />}
        onClick={() => {
          handleChangePasswordClick();
        }}
      >
        {t('changePassword')}
      </Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        onClick={() => {
          dispatch(logoutAsync());
        }}
      >
        {t('signOut')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <SettingOutlined style={{ fontSize: '130%' }} />
    </Dropdown>
  );
};
