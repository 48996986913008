import { DatePicker, Space } from 'antd';
import { setDatesForFetchingTagSumByCategoryByDate } from '../../../redux/features/tags/tagsSlice';
import { useAppDispatch } from '../../../redux/hooks';

const { RangePicker } = DatePicker;

const PieChartTagsHeader = () => {
  const dispatch = useAppDispatch();

  const handleRangePickerChange = ([from, to]: [string, string]) => {
    dispatch(
      setDatesForFetchingTagSumByCategoryByDate({
        from: new Date(from).getTime(),
        to: new Date(to).getTime() + 24 * 60 * 60 * 1000,
      })
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Space direction={'horizontal'}>
        <RangePicker onChange={(_, dates) => handleRangePickerChange(dates)} />
      </Space>
    </div>
  );
};
export default PieChartTagsHeader;
