import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import api from '../../../api/api';
import { RootState } from '../../store';
import { t } from '../translations/translations';
import SpecificationResponse from './SpecificationResponse';

// TODO refactor serverside paging to be generic

interface ServersideConfig {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter:
    | SorterResult<SpecificationResponse>
    | SorterResult<SpecificationResponse>[];
}

export interface SpecificationsState {
  specifications: SpecificationResponse[];
  filteredSpecificationsCount: number;
  isLoadingSpecifications: boolean;
  errorMessage: string | null;
  serversideConfig: ServersideConfig | null;
}

export const selectSpecifications = (state: RootState) => state.specifications;

const initialState: SpecificationsState = {
  specifications: [],
  filteredSpecificationsCount: 0,
  isLoadingSpecifications: false,
  errorMessage: null,
  serversideConfig: null,
};

interface SearchFilter {
  value: string | number[];
  property: string;
  operator: string;
}

export function getSearchFilter(
  filters: Record<string, FilterValue | null>
): SearchFilter[] {
  const searchFilters: SearchFilter[] = [];

  for (let property in filters) {
    // eslint-disable-next-line no-prototype-builtins
    if (filters.hasOwnProperty(property)) {
      const filterValue = filters[property];
      if (filterValue) {
        // TODO refactor magic strings
        if (
          property === 'gatewayIn' ||
          property === 'gatewayOut' ||
          property === 'tagId'
        ) {
          const value = filterValue;
          searchFilters.push(<SearchFilter>{
            value,
            operator: 'IN',
            property,
          });
        } else if (property === 'dateIn' || property === 'dateOut') {
          const value = filterValue;
          searchFilters.push(<SearchFilter>{
            value,
            operator: 'BETWEEN',
            property,
          });
        } else {
          const value = filterValue[0];
          searchFilters.push(<SearchFilter>{
            value,
            operator: 'LIKE',
            property,
          });
        }
      }
    }
  }
  return searchFilters;
}

export function getSorterOrder(
  sorter:
    | SorterResult<SpecificationResponse>
    | SorterResult<SpecificationResponse>[]
): SortOrder {
  // @ts-ignore
  if (!sorter.order) return { ascendingOrder: [], descendingOrder: [] };
  // @ts-ignore
  if (sorter.order === 'ascend') {
    return {
      // @ts-ignore
      ascendingOrder: [sorter.field],
      descendingOrder: [],
    };
  } else {
    return {
      // @ts-ignore
      descendingOrder: [sorter.field],
      ascendingOrder: [],
    };
  }
}

interface SortOrder {
  ascendingOrder: string[];
  descendingOrder: string[];
}

export const defaultServersideConfig: any = {
  pagination: { pageSize: 20 },
  filters: [],
  searchFilter: [],
  sortOrder: { ascendingOrder: [], descendingOrder: [] },
  pageNumber: 0,
  pageSize: 20,
};

function createServersidePagingPayload(serversideConfig?: ServersideConfig) {
  if (!serversideConfig) return defaultServersideConfig;

  const { pagination, filters, sorter } = serversideConfig;
  const sortOrder = getSorterOrder(sorter);
  const searchFilter = getSearchFilter(filters);

  return {
    searchFilter,
    sortOrder,
    pageNumber: (pagination.current || 1) - 1, // atnd pageNumber starts with 1
    pageSize: pagination.pageSize,
  };
}

export const invokePagingAsync = createAsyncThunk(
  'specifications/fetchSpecificationsServerside',
  async (serversideConfig?: ServersideConfig) => {
    const serversidePagingPayload =
      createServersidePagingPayload(serversideConfig);

    const countPromise = api.post(
      '/portal/hub/specification/countAllWithFilter',
      serversidePagingPayload
    );
    const dataPromise = api.post(
      '/portal/hub/specification/findAllWithFilter',
      serversidePagingPayload
    );

    return Promise.all([countPromise, dataPromise]).then(
      ([countResp, dataResp]) => {
        const filteredSpecificationsCount = countResp.data;
        const specifications = dataResp.data;
        return {
          specifications,
          filteredSpecificationsCount,
        };
      }
    );
  }
);

export const specificationsSlice = createSlice({
  name: 'specifications',
  initialState,
  reducers: {
    setServersideConfig: (state, action) => {
      state.serversideConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(invokePagingAsync.pending, (state) => {
        state.errorMessage = null;
        state.isLoadingSpecifications = true;
      })
      .addCase(invokePagingAsync.rejected, (state) => {
        state.errorMessage = t('errorDataLoading');
        state.isLoadingSpecifications = false;
      })
      .addCase(invokePagingAsync.fulfilled, (state, action) => {
        state.isLoadingSpecifications = false;
        state.specifications = action.payload.specifications;
        state.filteredSpecificationsCount =
          action.payload.filteredSpecificationsCount;
      });
  },
});

export const { setServersideConfig } = specificationsSlice.actions;

export default specificationsSlice.reducer;
