import { Layout, PageHeader } from 'antd';
import { selectTheme } from '../../../redux/features/theme/themeSlice';
import { useAppSelector } from '../../../redux/hooks';
import './Header.scss';
import DarkModeSwitch from '../DarkModeSwitch/DarkModeSwitch';
import { HeaderOptionsDropdownMenu } from '../Menu/HeaderOptionsDropdownMenu';

const { Header } = Layout;

const CustomHeader = () => {
  const { isDarkModeActive } = useAppSelector(selectTheme);

  return (
    <Header className={`headerContainer ${isDarkModeActive && 'dark-mode'}`}>
      <PageHeader
        title={
          <span>
            ENPay
            <i>
              <sub> portal</sub>
            </i>
          </span>
        }
      />
      <div className="rightSection">
        <div>
          <DarkModeSwitch />
        </div>
        <div>
          <HeaderOptionsDropdownMenu />
        </div>
      </div>
    </Header>
  );
};

export default CustomHeader;
