import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import interceptor from '../api/interceptor';
import aboutSliceReducer from './features/about/aboutSlice';
import authReducer from './features/auth/authSlice';
import commonStatesReducer from './features/common/commonSlice';
import countriesReducer from './features/countries/countriesSlice';
import commonDependenciesReducer from './features/dependencies/commonDependenciesSlice';
import invoiceReducer from './features/invoices/invoicesSlice';
import specificationsReducer from './features/specifications/specificationsSlice';
import tagReducer from './features/tags/tagsSlice';
import themeReducer from './features/theme/themeSlice';
import languagesReducer from './features/translations/translationsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    countries: countriesReducer,
    theme: themeReducer,
    tags: tagReducer,
    invoices: invoiceReducer,
    languages: languagesReducer,
    specifications: specificationsReducer,
    commonDependencies: commonDependenciesReducer,
    aboutInfo: aboutSliceReducer,
    common: commonStatesReducer,
  },
});

interceptor(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
