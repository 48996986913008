export const tagVehicleCategoryEnumMapping = (id: number) => {
  switch (id) {
    case 0: {
      return 'Ia';
    }
    case 1: {
      return 'I';
    }
    case 2: {
      return 'II';
    }
    case 3: {
      return 'III';
    }
    case 4: {
      return 'IV';
    }
  }
};
