import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../api/api';
import { InvoiceItemResponse } from '../../response/InvoiceItemResponse';
import InvoiceResponse from '../../response/InvoiceResponse';
import { RootState } from '../../store';
import { t } from '../translations/translations';

export interface InvoicesState {
  invoices: InvoiceResponse[];
  isLoadingInvoices: boolean;
  errorMessage: string | null;
  isLoadingInvoiceItems: boolean;
  invoiceItems: InvoiceItemResponse[];
  invoiceItemModalVisible: boolean;
  selectedInvoice: InvoiceResponse | null;
  summaryTotalSum: number;
  summaryVoidedSum: number;
}

export const selectInvoices = (state: RootState) => state.invoices;

const initialState: InvoicesState = {
  invoices: [],
  isLoadingInvoices: false,
  errorMessage: null,
  isLoadingInvoiceItems: false,
  invoiceItems: [],
  invoiceItemModalVisible: false,
  selectedInvoice: null,
  summaryTotalSum: 0,
  summaryVoidedSum: 0,
};

export const fetchInvoicesAsync = createAsyncThunk(
  'invoices/fetchInvoices',
  async () => {
    const response = await api.get('/portal/hub/invoices');
    return response.data;
  }
);

export const fetchInvoiceItemsAsync = createAsyncThunk(
  'invoices/fetchInvoiceItems',
  async (invoice: InvoiceResponse) => {
    const response = await api.get(
      `/portal/hub/getAllByInvoiceId/${invoice.id}`
    );
    return { invoice, invoiceItems: response.data };
  }
);

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setVisibilityInvoiceItemModal: (state, payload: PayloadAction<boolean>) => {
      state.invoiceItemModalVisible = payload.payload;
    },
    setSummarySums: (
      state,
      payload: PayloadAction<{ totalSum: number; voidedSum: number }>
    ) => {
      state.summaryTotalSum = payload.payload.totalSum;
      state.summaryVoidedSum = payload.payload.voidedSum;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicesAsync.pending, (state) => {
        state.isLoadingInvoices = true;
        state.errorMessage = null;
      })
      .addCase(fetchInvoicesAsync.rejected, (state) => {
        state.errorMessage = t('errorLoadingData');
        state.isLoadingInvoices = false;
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.isLoadingInvoices = false;
        state.invoices = action.payload;
        let voidedSum = 0;
        let totalSum = 0;
        for (let i = 0; i < action.payload.length; i++) {
          voidedSum += action.payload.at(i).voidedAmount;
          totalSum += action.payload.at(i).amount;
        }
        state.summaryVoidedSum = voidedSum;
        state.summaryTotalSum = totalSum;
      })
      .addCase(fetchInvoiceItemsAsync.pending, (state) => {
        state.isLoadingInvoiceItems = true;
        state.errorMessage = null;
      })
      .addCase(fetchInvoiceItemsAsync.rejected, (state) => {
        state.isLoadingInvoiceItems = false;
        state.errorMessage = t('errorLoadingData');
      })
      .addCase(fetchInvoiceItemsAsync.fulfilled, (state, action) => {
        state.isLoadingInvoiceItems = false;
        state.invoiceItems = action.payload.invoiceItems;
        state.selectedInvoice = action.payload.invoice;
      });
  },
});

export const { setVisibilityInvoiceItemModal, setSummarySums } =
  invoicesSlice.actions;
export default invoicesSlice.reducer;
