import React from 'react';
import { enpayBig } from '../../assets';
import { selectTheme } from '../../redux/features/theme/themeSlice';
import { useAppSelector } from '../../redux/hooks';
import './LoadingPage.scss';

const LoadingPage = () => {
  const { isDarkModeActive } = useAppSelector(selectTheme);

  return (
    <div className={`loadingPageContainer ${isDarkModeActive && 'dark-mode'}`}>
      <div className="loadingPageContent">
        <div className="logoContainer">
          <img src={enpayBig} alt="ENPay" />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
