import { I, Ia, II, III, IV } from '../../../assets';
import SVGWrapper from '../../../pages/wrappers/SVGWrapper';

const tagVehicleCategoryEnumToImageMapping = (id: number) => {
  switch (id) {
    case 0: {
      return SVGWrapper(Ia);
    }
    case 1: {
      return SVGWrapper(I, 3);
    }
    case 2: {
      return SVGWrapper(II, 6.365);
    }
    case 3: {
      return SVGWrapper(III, 6.365);
    }
    case 4: {
      return SVGWrapper(IV, 6.365);
    }
  }
};
export default tagVehicleCategoryEnumToImageMapping;

export const tagVehicleCategoryEnumToImageMappingWrapped = (
  id: number,
  isDarkModeActive: boolean
) => {
  return (
    <div className={isDarkModeActive ? 'dark-mode' : ''}>
      {tagVehicleCategoryEnumToImageMapping(id)}
    </div>
  );
};
