import React from 'react';
import { Button, Modal, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import {
  selectInvoices,
  setVisibilityInvoiceItemModal,
} from '../../../redux/features/invoices/invoicesSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { InvoiceItemResponse } from '../../../redux/response/InvoiceItemResponse';
import { numberFormatter } from '../../../util/utilFunctions';

const { Text } = Typography;

const InvoiceItemModal = () => {
  const dispatch = useAppDispatch();
  const { invoiceItemModalVisible, selectedInvoice } =
    useAppSelector(selectInvoices);

  const handleOk = () => {
    dispatch(setVisibilityInvoiceItemModal(false));
  };

  const { invoiceItems, isLoadingInvoiceItems } =
    useAppSelector(selectInvoices);

  const invoiceItemsColumns: ColumnType<InvoiceItemResponse>[] = [
    {
      title: t('invoiceId'),
      dataIndex: 'invoiceId',
      sorter: (invoiceItem1, invoiceItem2) =>
        invoiceItem1.invoiceId > invoiceItem2.invoiceId ? -1 : 1,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: (invoiceItem1, invoiceItem2) =>
        invoiceItem1.name?.localeCompare(invoiceItem2.name),
    },
    {
      title: t('price'),
      dataIndex: 'price',
      sorter: (invoiceItem1, invoiceItem2) =>
        invoiceItem1.price - invoiceItem2.price,
      render: (_, invoiceItem) => numberFormatter(invoiceItem.price),
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      sorter: (invoiceItem1, invoiceItem2) =>
        invoiceItem1.quantity - invoiceItem2.quantity,
    },
  ];

  return (
    <Modal
      title={t('invoiceItems') + ' - ' + selectedInvoice?.number}
      width={900}
      visible={invoiceItemModalVisible}
      onCancel={handleOk}
      footer={[
        <Button key="back" onClick={handleOk}>
          {t('ok')}
        </Button>,
      ]}
    >
      <Table
        bordered
        loading={isLoadingInvoiceItems}
        columns={invoiceItemsColumns}
        dataSource={invoiceItems}
        rowKey="id"
        pagination={false}
        summary={(data) => {
          let sum = 0;
          data.forEach(({ price }) => {
            sum += price;
          });
          return (
            <>
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type={'secondary'}>{t('total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={2}>
                    <Text type={'secondary'}>{numberFormatter(sum)}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default InvoiceItemModal;
