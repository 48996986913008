export const tagStatusEnumMapping = (status: number) => {
  switch (status) {
    case 1: {
      return 'ACTIVE';
    }
    case 2: {
      return 'DEFECTIVE';
    }
    case 3: {
      return 'LOST';
    }
    case 4: {
      return 'BLOCKED';
    }
    default: {
      return 'OTHER';
    }
  }
};
