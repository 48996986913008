import React from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  selectInvoices,
  setSummarySums,
} from '../../../redux/features/invoices/invoicesSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import InvoiceResponse from '../../../redux/response/InvoiceResponse';
import invoicesColumns from './invoicesColumns';
import InvoicesSummaryHeader from './InvoicesSummaryHeader';

const InvoicesTable = () => {
  const { invoices, isLoadingInvoices, errorMessage } =
    useAppSelector(selectInvoices);
  const dispatch = useAppDispatch();
  useErrorMessage(errorMessage);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InvoiceResponse> | SorterResult<InvoiceResponse>[],
    extra: { currentDataSource: Array<any> }
  ) => {
    let voidedSum = 0;
    let totalSum = 0;
    for (let i = 0; i < extra.currentDataSource.length; i++) {
      voidedSum += extra.currentDataSource.at(i).voidedAmount;
      totalSum += extra.currentDataSource.at(i).amount;
    }
    dispatch(setSummarySums({ voidedSum, totalSum }));
  };

  return (
    <Table
      title={() => <InvoicesSummaryHeader />}
      onChange={(pagination, filters, sorter, extra) =>
        handleChange(pagination, filters, sorter, extra)
      }
      size={'small'}
      loading={isLoadingInvoices}
      columns={invoicesColumns()}
      dataSource={invoices}
      rowKey="id"
      pagination={{ position: ['topRight'], defaultPageSize: 20 }}
      scroll={{ y: 'calc(100vh - 300px)' }}
    />
  );
};

export default InvoicesTable;
