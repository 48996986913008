export const tagStatusColorMapping = (id: number) => {
  switch (id) {
    case 1: {
      return 'success'; //active
    }
    case 2: {
      return 'error'; //defective
    }
    case 3: {
      return 'processing'; //lost
    }
    case 4: {
      return 'error'; //blocked
    }
    case 5: {
      return 'default'; //other
    }
  }
};
