import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Table, Tag } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { selectTags } from '../../../redux/features/tags/tagsSlice';
import { tagStatusColorMapping } from '../../../redux/features/tags/tagStatusColorMapping';
import { tagStatusEnumMapping } from '../../../redux/features/tags/tagStatusEnumMapping';
import tagVehicleCategoryEnumToImageMapping, {
  tagVehicleCategoryEnumToImageMappingWrapped,
} from '../../../redux/features/tags/tagVehicleCategoryEnumToImageMapping';
import { selectTheme } from '../../../redux/features/theme/themeSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppSelector } from '../../../redux/hooks';
import { TagResponse } from '../../../redux/response/TagResponse';

//TODO:REFACTOR!
const TagsTable = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const { tags, isLoadingTags, errorMessage } = useAppSelector(selectTags);
  const { isDarkModeActive } = useAppSelector(selectTheme);
  type TagResponseIndex = keyof TagResponse;

  useErrorMessage(errorMessage);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: TagResponseIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (
    dataIndex: TagResponseIndex
  ): ColumnType<TagResponse> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={t(`${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('Search')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t('reset')}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t('filter')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const tagsColumns: ColumnType<TagResponse>[] = [
    {
      title: t('licencePlate'),
      dataIndex: 'licencePlate',
      sorter: (tag1, tag2) =>
        tag2.licencePlate?.localeCompare(tag1.licencePlate),
      ...getColumnSearchProps('licencePlate'),
    },
    {
      title: t('innerNumber'),
      dataIndex: 'innerNumber',
      sorter: (tag1, tag2) => tag2.innerNumber?.localeCompare(tag1.innerNumber),
      ...getColumnSearchProps('innerNumber'),
    },
    {
      title: t('outerNumber'),
      dataIndex: 'outerNumber',
      sorter: (tag1, tag2) => tag2.outerNumber?.localeCompare(tag1.outerNumber),
      ...getColumnSearchProps('outerNumber'),
    },
    {
      title: t('vehicleCategory'),
      dataIndex: 'vehicleCategory',
      filters: [
        {
          text: tagVehicleCategoryEnumToImageMappingWrapped(
            0,
            isDarkModeActive
          ),
          value: 0,
        },
        {
          text: tagVehicleCategoryEnumToImageMappingWrapped(
            1,
            isDarkModeActive
          ),
          value: 1,
        },
        {
          text: tagVehicleCategoryEnumToImageMappingWrapped(
            2,
            isDarkModeActive
          ),
          value: 2,
        },
        {
          text: tagVehicleCategoryEnumToImageMappingWrapped(
            3,
            isDarkModeActive
          ),
          value: 3,
        },
        {
          text: tagVehicleCategoryEnumToImageMappingWrapped(
            4,
            isDarkModeActive
          ),
          value: 4,
        },
      ],
      onFilter: (value, tag) => tag.vehicleCategory === value,
      filterMode: 'tree',
      sorter: (tag1, tag2) => tag1.vehicleCategory - tag2.vehicleCategory,
      render: (_, tag) =>
        tagVehicleCategoryEnumToImageMapping(tag.vehicleCategory),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (tag1, tag2) => tag1.status - tag2.status,
      filters: [
        { text: t(tagStatusEnumMapping(1)), value: 1 },
        { text: t(tagStatusEnumMapping(2)), value: 2 },
        { text: t(tagStatusEnumMapping(3)), value: 3 },
        { text: t(tagStatusEnumMapping(4)), value: 4 },
        { text: t(tagStatusEnumMapping(5)), value: 5 },
      ],
      onFilter: (value, tag) => tag.status === value,
      filterMode: 'tree',
      render: (_, tag) => (
        <Tag color={tagStatusColorMapping(tag.status)}>
          {t(tagStatusEnumMapping(tag.status))}
        </Tag>
      ),
    },
  ];

  return (
    <Table
      loading={isLoadingTags}
      columns={tagsColumns}
      size={'small'}
      dataSource={tags}
      rowKey="id"
      pagination={{ position: ['topRight'], defaultPageSize: 20 }}
      scroll={{ y: 'calc(100vh - 300px)' }}
    />
  );
};

export default TagsTable;
