import { Switch } from 'antd';
import {
  changeTheme,
  selectTheme,
} from '../../../redux/features/theme/themeSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import './DarkModeSwitch.scss';

const DarkModeSwitch = () => {
  const dispatch = useAppDispatch();
  const { isDarkModeActive } = useAppSelector(selectTheme);

  const handleSwitchPress = () => {
    dispatch(changeTheme());
  };

  return (
    <div className="darkModeSwitch">
      Dark mode
      <Switch
        size={'small'}
        onChange={handleSwitchPress}
        checked={isDarkModeActive}
      />
    </div>
  );
};

export default DarkModeSwitch;
