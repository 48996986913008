import React, { useRef, useState } from 'react';
import {
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Input, InputRef, Space, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Moment from 'moment';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import {
  fetchInvoiceItemsAsync,
  setVisibilityInvoiceItemModal,
} from '../../../redux/features/invoices/invoicesSlice';
import { invoicesTypeEnumMapping } from '../../../redux/features/invoices/invoicesTypeEnumMapping';
import { InvoiceTypeEnum } from '../../../redux/features/invoices/InvoiceTypeEnum';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch } from '../../../redux/hooks';
import InvoiceResponse from '../../../redux/response/InvoiceResponse';
import { numberFormatter } from '../../../util/utilFunctions';

const { RangePicker } = DatePicker;
const invoicesColumns = function (): ColumnType<InvoiceResponse>[] {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [searchPeriod, setSearchPeriod] = useState({
    startTime: 0,
    endTime: 0,
  });

  const rightAlignedText = (text: string) => {
    return <div style={{ textAlign: 'right' }}>{text}</div>;
  };

  type InvoiceResponseIndex = keyof InvoiceResponse;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: InvoiceResponseIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (
    dataIndex: InvoiceResponseIndex
  ): ColumnType<InvoiceResponse> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === 'createdDate' ? (
          <>
            <RangePicker
              onChange={(e) => {
                if (e && e[0] && e[1]) {
                  setSelectedKeys([
                    e[0].toDate().getTime(),
                    e[1].toDate().getTime(),
                  ]);
                  setSearchPeriod({
                    startTime: e[0].toDate().getTime(),
                    endTime: e[1].toDate().getTime(),
                  });
                } else {
                  setSelectedKeys([]);
                }
              }}
              style={{ marginBottom: 8 }}
            />
            <br />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('filter')}
              </Button>
            </Space>
          </>
        ) : (
          <>
            <Input
              ref={searchInput}
              placeholder={t(`${dataIndex}`)}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText((selectedKeys as string[])[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                {t('filter')}
              </Button>
            </Space>
          </>
        )}
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'createdDate') {
        if (!searchPeriod.startTime || !searchPeriod.endTime) return true;
        return (
          moment(record[dataIndex]).unix() >
            moment(searchPeriod.startTime).unix() &&
          moment(record[dataIndex]).unix() < moment(searchPeriod.endTime).unix()
        );
      }
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleOnClickInvoiceDownload = (invoiceId: number) => {
    window.open(
      `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadInvoice/${invoiceId}`,
      '_blank'
    );
  };
  const handleOnClickDownloadSpecificationsForInvoice = (invoiceId: number) => {
    window.open(
      `${process.env.REACT_APP_URL}/portal/hub/pdf/downloadSpecification/${invoiceId}`,
      '_blank'
    );
  };
  //TODO:update url when backend gets done
  const handleOnClickDownloadSpecificationsForInvoiceXLS = (
    invoiceId: number
  ) => {
    window.open(
      `${process.env.REACT_APP_URL}/portal/hub/xls/downloadSpecification/${invoiceId}`,
      '_blank'
    );
  };

  const handleOnClickViewInvoiceItems = (invoice: InvoiceResponse) => {
    dispatch(fetchInvoiceItemsAsync(invoice));
    dispatch(setVisibilityInvoiceItemModal(true));
  };

  return [
    {
      title: t('number'),
      dataIndex: 'number',
      sorter: (invoice1, invoice2) =>
        invoice1.number?.localeCompare(invoice2.number),
      ...getColumnSearchProps('number'),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      filters: [
        { text: t(invoicesTypeEnumMapping(1)), value: 1 },
        { text: t(invoicesTypeEnumMapping(2)), value: 2 },
        { text: t(invoicesTypeEnumMapping(3)), value: 3 },
        { text: t(invoicesTypeEnumMapping(5)), value: 5 },
      ],
      onFilter: (value, tag) => tag.type === value,
      filterMode: 'tree',
      sorter: (invoice1, invoice2) => invoice2.type - invoice1.type,
      render: (_, invoice) => t(invoicesTypeEnumMapping(invoice.type)),
    },
    {
      title: t('paid'),
      dataIndex: 'paid',
      filters: [
        { text: t('yes'), value: 1 },
        { text: t('no'), value: 0 },
      ],
      filterMode: 'tree',
      onFilter: (value, invoice) => invoice.paid === value,

      sorter: (invoice1, invoice2) => (invoice1.paid > invoice2.paid ? 1 : -1),
      render: (_, invoice) => (
        <Tag color={invoice.paid ? 'limegreen' : 'orangered'}>
          {invoice.paid ? t('yes') : t('no')}
        </Tag>
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      onFilter: (value, invoice) => invoice.amount === value,
      filterMode: 'tree',
      sorter: (invoice1, invoice2) => invoice1.amount - invoice2.amount,
      render: (_, invoice) => {
        return rightAlignedText(numberFormatter(invoice.amount));
      },
    },
    {
      title: t('voidedAmount'),
      dataIndex: 'voidedAmount',
      sorter: (invoice1, invoice2) =>
        invoice1.voidedAmount - invoice2.voidedAmount,
      onFilter: (value, invoice) => invoice.voidedAmount === value,
      filterMode: 'tree',
      render: (_, invoice) => {
        return rightAlignedText(
          !invoice.voidedAmount ? '0' : numberFormatter(invoice.voidedAmount)
        );
      },
    },
    {
      title: t('actions'),
      dataIndex: 'id',
      key: 'id',
      render: (id, invoice) => (
        <div
          style={{
            display: 'flex',
            marginLeft: '20px',
            justifyContent: 'left',
            gap: '5px',
          }}
        >
          <Tooltip title={t('downloadInvoicesBtn')}>
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                handleOnClickInvoiceDownload(invoice.id);
              }}
            />
          </Tooltip>
          <Tooltip title={t('invoiceItems')}>
            <Button
              icon={<UnorderedListOutlined />}
              onClick={() => {
                handleOnClickViewInvoiceItems(invoice);
              }}
            />
          </Tooltip>
          {invoice.type === InvoiceTypeEnum.specification && (
            <>
              <Tooltip title={t('downloadSpecificationsForInvoice')}>
                <Button
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    handleOnClickDownloadSpecificationsForInvoice(invoice.id);
                  }}
                />
              </Tooltip>

              <Tooltip title={t('downloadSpecificationsForInvoiceXLSFormat')}>
                <Button
                  icon={<FileExcelOutlined />}
                  onClick={() => {
                    handleOnClickDownloadSpecificationsForInvoiceXLS(
                      invoice.id
                    );
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'createdDate',
      defaultSortOrder: 'descend',
      sorter: (invoice1, invoice2) =>
        new Date(invoice1.createdDate).getTime() -
        new Date(invoice2.createdDate).getTime(),
      ...getColumnSearchProps('createdDate'),
      render: (_, invoice) =>
        Moment(invoice.createdDate).format('DD.MM.yyyy. HH:mm:ss'),
    },
  ];
};

export default invoicesColumns;
