import { useEffect } from 'react';
import { message } from 'antd';

const useErrorMessage = (errorMessage: string | null) => {
  useEffect(() => {
    if (errorMessage) message.error(errorMessage);
  }, [errorMessage]);
};

export default useErrorMessage;
