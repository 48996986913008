import { Store } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setUserLoggedOut } from '../redux/features/auth/authSlice';
import api from './api';

const interceptor = (store: Store) => {
  api.interceptors.request.use(function (config) {
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        store.dispatch(setUserLoggedOut());
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
