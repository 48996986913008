import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import api from '../../../api/api';
import { TagsByCategorySumByDatePeriod } from '../../request/TagsByCategorySumByDatePeriod';
import { TagsByDatePeriodOption } from '../../request/TagsByDatePeriodOption';
import { TagsByDateRequest } from '../../request/TagsByDateRequest';
import { TagResponse } from '../../response/TagResponse';
import { TagsByCategoryResponse } from '../../response/TagsByCategoryResponse';
import { TagsSumByDateResponse } from '../../response/TagsSumByDateResponse';
import { RootState } from '../../store';
import { t } from '../translations/translations';

export interface TagsState {
  tags: TagResponse[];
  isLoadingTags: boolean;
  errorMessage: string | null;
  isLoadingTagsByCategory: boolean;
  isLoadingTagsSumByDate: boolean;
  tagsByCategory: TagsByCategoryResponse[];
  tagsSumByDate: TagsSumByDateResponse[];
  selectedTagsSumOption: TagsByDateRequest;
  selectedTagsSumByCategoryOption: TagsByCategorySumByDatePeriod;
}

export const selectTags = (state: RootState) => state.tags;

const initialState: TagsState = {
  tags: [],
  isLoadingTags: false,
  errorMessage: null,
  isLoadingTagsByCategory: false,
  isLoadingTagsSumByDate: false,
  tagsByCategory: [],
  tagsSumByDate: [],
  selectedTagsSumOption: {
    period: TagsByDatePeriodOption.day,
    periodStart: moment(Date.now()).subtract(1, 'months').toDate().getTime(),
    periodEnd: new Date().getTime(),
  },
  selectedTagsSumByCategoryOption: {
    periodStart: moment(Date.now()).subtract(1, 'months').toDate().getTime(),
    periodEnd: new Date().getTime(),
  },
};

export const fetchTagsAsync = createAsyncThunk('tags/fetchTags', async () => {
  const response = await api.get('/portal/hub/tag');
  return response.data;
});

export const fetchTagsByCategoryAsync = createAsyncThunk(
  'tags/fetchTagsByCategory',
  async (data: TagsByCategorySumByDatePeriod) => {
    const response = await api.get(
      `/portal/hub/amountByCategory/start/${data.periodStart}/end/${data.periodEnd}`
    );
    return response.data;
  }
);

export const fetchTagsSumByDate = createAsyncThunk(
  'tags/fetchTagSumByDate',
  async (data: TagsByDateRequest) => {
    const response = await api.get(
      `/portal/hub/line/period/${data.period}/start/${data.periodStart}/end/${data.periodEnd}`
    );
    return response.data;
  }
);

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setSelectedPeriodForFetchingTagsSumByDate: (
      state,
      action: PayloadAction<TagsByDatePeriodOption>
    ) => {
      state.selectedTagsSumOption.period = action.payload;
    },
    setDatesForFetchingTagsSumByDate: (
      state,
      action: PayloadAction<{ from: number; to: number }>
    ) => {
      state.selectedTagsSumOption.periodStart = action.payload.from;
      state.selectedTagsSumOption.periodEnd = action.payload.to;
    },
    setDatesForFetchingTagSumByCategoryByDate: (
      state,
      action: PayloadAction<{ from: number; to: number }>
    ) => {
      state.selectedTagsSumByCategoryOption.periodStart = action.payload.from;
      state.selectedTagsSumByCategoryOption.periodEnd = action.payload.to;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagsAsync.pending, (state) => {
        state.isLoadingTags = true;
        state.errorMessage = null;
      })
      .addCase(fetchTagsAsync.rejected, (state) => {
        state.errorMessage = t('errorLoadingData');
        state.isLoadingTags = false;
      })
      .addCase(
        fetchTagsAsync.fulfilled,
        (state, action: PayloadAction<[TagResponse]>) => {
          state.tags = action.payload;
          state.isLoadingTags = false;
        }
      )
      .addCase(fetchTagsByCategoryAsync.pending, (state) => {
        state.tagsByCategory = [];
        state.isLoadingTagsByCategory = true;
        state.errorMessage = null;
      })
      .addCase(fetchTagsByCategoryAsync.rejected, (state) => {
        state.isLoadingTagsByCategory = false;
        state.errorMessage = t('errorLoadingData');
      })
      .addCase(
        fetchTagsByCategoryAsync.fulfilled,
        (state, action: PayloadAction<[TagsByCategoryResponse]>) => {
          state.isLoadingTagsByCategory = false;
          state.tagsByCategory = action.payload;
        }
      )
      .addCase(fetchTagsSumByDate.pending, (state) => {
        state.isLoadingTagsSumByDate = true;
        state.errorMessage = null;
      })
      .addCase(fetchTagsSumByDate.rejected, (state) => {
        state.isLoadingTagsSumByDate = false;
        state.errorMessage = t('errorLoadingData');
      })
      .addCase(
        fetchTagsSumByDate.fulfilled,
        (state, action: PayloadAction<[TagsSumByDateResponse]>) => {
          state.isLoadingTagsSumByDate = false;
          state.tagsSumByDate = action.payload;
        }
      );
  },
});

export const {
  setSelectedPeriodForFetchingTagsSumByDate,
  setDatesForFetchingTagsSumByDate,
  setDatesForFetchingTagSumByCategoryByDate,
} = tagsSlice.actions;

export default tagsSlice.reducer;
