import { useEffect } from 'react';
import { Button, Input, Modal, Space } from 'antd';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSuccessMessage from '../../../hooks/useSuccessMessage';
import {
  changePasswordAsync,
  selectAuth,
  setChangePasswordModalVisible,
  setChangingPasswordError,
  setNewPassword,
  setOldPassword,
  setRepeatedNewPassword,
} from '../../../redux/features/auth/authSlice';
import { t } from '../../../redux/features/translations/translations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const ChangePasswordModal = () => {
  const dispatch = useAppDispatch();
  const {
    oldPassword,
    newPassword,
    repeatNewPassword,
    changingPasswordError,
    changePasswordModalVisible,
    changingPasswordSuccessMessage,
  } = useAppSelector(selectAuth);

  useEffect(() => {
    if (changingPasswordSuccessMessage) {
      dispatch(setChangePasswordModalVisible(false));
    }
  }, [changingPasswordSuccessMessage]);

  useSuccessMessage(changingPasswordSuccessMessage);
  useErrorMessage(changingPasswordError);

  const handlePasswordChange = () => {
    if (newPassword && oldPassword) {
      if (newPassword === repeatNewPassword) {
        if (newPassword !== oldPassword) {
          dispatch(
            changePasswordAsync({
              password: newPassword,
              currentPassword: oldPassword,
            })
          );
        } else {
          dispatch(setChangingPasswordError(t('passwordMustDifferFromOld')));
        }
      } else {
        dispatch(setChangingPasswordError(t('passwordsDoNotMatch')));
      }
    } else {
      dispatch(setChangingPasswordError(t('errorPasswordChange')));
    }
  };

  const handleOnCancel = () => {
    dispatch(setChangePasswordModalVisible(false));
  };

  return (
    <Modal
      onCancel={handleOnCancel}
      visible={changePasswordModalVisible}
      footer={[
        <Button key="submit" onClick={handlePasswordChange} type={'default'}>
          {t('changePassword')}
        </Button>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          paddingTop: 30,
        }}
      >
        <Space direction={'vertical'}>
          <Input.Password
            value={oldPassword}
            onChange={(e) => {
              dispatch(setOldPassword(e.target.value));
            }}
            placeholder={t('currentPasswordLabel')}
            width={350}
          />
          <Input.Password
            value={newPassword}
            onChange={(e) => {
              dispatch(setNewPassword(e.target.value));
            }}
            placeholder={t('newPasswordLabel')}
          />
          <Input.Password
            value={repeatNewPassword}
            onChange={(e) => {
              dispatch(setRepeatedNewPassword(e.target.value));
            }}
            placeholder={t('passwordRepeatLabel')}
          />
        </Space>
      </div>
    </Modal>
  );
};
export default ChangePasswordModal;
