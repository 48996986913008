import api from '../../../api/api';
import { Translation } from './Translation';

const translations: Translation = {};

export const fetchLocalisationAsync = (languageId: string) => {
  return api
    .get('/portal/translations/getByLanguageId/' + languageId)
    .then((res) => {
      res.data.forEach((element: { key: string; value: string }) => {
        translations[element.key] = element.value;
      });
    });
};

export const t = (key: string) => {
  return translations[key] || key;
};
