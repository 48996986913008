import React from 'react';
import {
  CarOutlined,
  FileOutlined,
  HomeOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { t } from '../../redux/features/translations/translations';
import RoutesEnum from '../../routing/RoutesEnum';

type SideMenuItemType = ItemType & { link: RoutesEnum };

const siteMenuItems = function (): SideMenuItemType[] {
  return [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: t('dashboard'),
      link: RoutesEnum.HOME,
    },
    {
      key: '/tags',
      icon: <TagsOutlined />,
      label: t('tags'),
      link: RoutesEnum.TAGS,
    },
    {
      key: '/invoices',
      icon: <FileOutlined />,
      label: t('invoices'),
      link: RoutesEnum.INVOICES,
    },
    {
      key: '/specifications',
      icon: <CarOutlined />,
      label: t('specification'),
      link: RoutesEnum.SPECIFICATIONS,
    },
  ];
};

export default siteMenuItems;
